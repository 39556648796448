import React, {useState}                        from 'react'
import moment                                   from 'moment';
import DashboardLayout                          from 'layouts/dashboardLayout'
import Card                                     from 'layouts/card'
import userIcon                                 from 'images/user-icon.png'
import Switch                                   from "react-switch";
import useUserDetails                           from './use-user-details';
import DataTable                                from 'react-data-table-component';
import BasicInput                               from 'components/inputs/basicInput';
import CheckBoxInput                            from 'components/inputs/checkBoxInput';
import AvatarWithTextSkeleton                   from 'components/skeleton/AvatarWithTextSkeleton';
import ListSkeleton                             from 'components/skeleton/ListSkeleton';
import AddBuildingAccess                        from './add-building-access/add-building-access';

function UserDetasilsScreen() {

    const {
        data,
        user,
        filteredApprovedBuildings,
        filterApprovedBuildings,
        addBuildingAccessModal,
        filteredEventLog,
        switchStatus,
        handleSwitchChange,
        revokeAccess,
        approvedBuildingCheckBoxes, 
        setApprovedBuildingCheckBoxes,
        isLoading, 
    } = useUserDetails();

    const [count, setCount] = useState(0)

    const handleCheckboxChange = (checkboxName) => {
        if(!approvedBuildingCheckBoxes[checkboxName]) {
            setCount(count + 1);
        } else {
            setCount(count - 1);
        }
        setApprovedBuildingCheckBoxes({
          ...approvedBuildingCheckBoxes,
          [checkboxName]: !approvedBuildingCheckBoxes[checkboxName]
        });
    };

    const columns = [
        {
            name: 'Building Name',
            selector: row => row.buildingName,
        },
        {
            name: 'Key Tag',
            selector: row => row.keyName,
        },
        {
            name: 'Key Number',
            selector: row => row.keyNumber,
        },
        {
            name: 'Action',
            cell : (row) => (
                <p className={`badge ${row.action == 'return_key' ? 'reception-badge' : 'guest-badge'}`}>{row.action == 'return_key' ? 'Return' : 'Issue'}</p>
            )
        },
        {
            name: 'Timestamp',
            selector: row => row.timestamp,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                backgroundColor : '#dff5e5',
            },
        },
        cells: {
            style: {
                paddingRight: '8px',
            },
        },
    };


    return (
    <DashboardLayout pageTitle="Manage User" backHeader={true}>
        <div className="user-details">
            <Card>
                {!isLoading ?
                <div className="user-profile-card">
                    <div className='user-profile-card__left'>
                        <div className="profile-pic">
                            <img src={userIcon} alt="User Default Icon" />
                        </div>
                        <div className="profile-details">
                            <h2>{data.fullName}</h2>
                            <p>{data.email}</p>
                            <div className="profile-badges gap-1">
                                <p className={`badge ${data.accountType == 'user' ? 'reception-badge' : data.accountType == 'guest' || data.accountType == 'contractor-guest' ? 'guest-badge' : data.accountType == 'contractor' ? 'contractor-badge' : 'manager-badge'}`}>
                                    {data.accountType == 'user' ? 'Reception User' : data.accountType == 'guest' || data.accountType == 'contractor-guest' ? 'Guest User' : data.accountType == 'contractor' ? 'Contractor' : 'Manager'}
                                </p>
                                <p className={`badge ${data.approvalFlag ? 'active-badge' : 'in-active-badge'}`}>Status: {data.approvalFlag ? 'Active' : 'Not Active'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='user-profile-card__right'>
                        <div className="profile-status">
                            {data.accountType !== 'manager' ?
                            <div className="user-status">
                                <p>User Status:</p>
                                <Switch 
                                    onChange={handleSwitchChange} 
                                    checked={switchStatus} 
                                />
                            </div> : '' }
                            <p>User Created On: {moment(data.startDate.toDate()).format('MM/DD/YYYY')}</p>
                        </div>
                    </div>
                </div> : 
                <div style={{ width : "100%" }}>
                    <AvatarWithTextSkeleton /> 
                </div>
                }
            </Card>
                <div className="user-details__row" style={{ alignItems: 'flex-start' }}>
                    <div className="approved-buildings">
                        <Card classNames="mt-2" disabled={data.accountType == 'manager' ? true : false} disabledMessage="You are restricted to use this section">
                            <h3>Approved Buildings</h3>
                            {!isLoading ?
                            <>
                                <div className="options">
                                    {
                                        filteredApprovedBuildings.length > 0 ? 
                                        <p className={`revoke ${count > 0 ? 'active' : 'inactive'}`} onClick={count > 0 ? revokeAccess : ()=>{}}>X Revoke Access</p> :
                                        ''
                                    }
                                    <p className='add' onClick={addBuildingAccessModal}>+ Add Building Access</p>
                                </div>
                                <div className="mt-2">
                                    <BasicInput placeholder="Search by Building Name" onChange={(e) => filterApprovedBuildings(e.target.value)} />
                                    <div className="mt-3 scroll">
                                        {
                                        filteredApprovedBuildings.length > 0 ?
                                        filteredApprovedBuildings.map((building) => {
                                            return (
                                                <CheckBoxInput
                                                    key={building.id} 
                                                    label={
                                                        <>  
                                                            <p>{building.name.name}{data.currentBuilding == building.id ? <span className='current'>Current Building</span> : '' }</p>
                                                        </>
                                                    }
                                                    id={building.id}
                                                    checked={approvedBuildingCheckBoxes[building.id]}
                                                    onChange={() => handleCheckboxChange(building.id)}
                                                />
                                            )
                                        })  : 
                                        <>
                                            <p>No Buildings To Display</p>
                                        </> 
                                    }
                                    </div>
                                </div>
                            </> : <ListSkeleton />}
                        </Card>
                    </div>
                    <div className="event-logs">
                        <Card classNames="mt-2">
                            <h3>Event Logs</h3>
                            <div className="event-logs__list">
                            {!isLoading ?
                                <DataTable
                                    columns={columns}
                                    data={filteredEventLog}
                                    customStyles={customStyles}
		                            dense
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 15, 20]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: "Records per page:",
                                        rangeSeparatorText: "out of"
                                    }}
                                /> : <ListSkeleton />}
                            </div>
                        </Card>
                    </div>
                </div>
        </div>
        <AddBuildingAccess />
    </DashboardLayout>
  )
}

export default UserDetasilsScreen