import React from 'react'
import { BounceLoader, PuffLoader  } from 'react-spinners'

// Basic button with theme color - small size than theme button
// title:   Title in the button
// onClick: Function when button is pressed
function BasicThemeButton({title, onClick, color, fontColor, width, disableStatus = false}) {
  return (
    <div className='basic-theme-button' style={{ width : width ? width : 'auto' }}>
        <button 
          type='submit' 
          onClick={onClick} 
          style={{ 
            backgroundColor : disableStatus ? '#a3a3a3' : color ? color : '', 
            borderColor : color ? color : '', 
            color : disableStatus ? '#fff' : fontColor ? fontColor : '',
            cursor : disableStatus ? 'not-allowed' : true
          }} 
        >
          <BounceLoader 
            color={disableStatus ? '#fff' : fontColor ? fontColor : '#fff'} 
            loading={disableStatus} 
            size={20} 
          />
          {title}
        </button>
    </div>
  )
}

export default BasicThemeButton

// Stylesheet: sass/components/buttons/_buttons.scss