import React, {useState, useEffect, useContext} from 'react'
import { CSVLink }                              from "react-csv";
import { capitalizeWords }                      from 'functions/functions';
import { ToastContainer, toast }                from 'react-toastify';
import { getAllKeyLoanData,}                    from '../services/keyServices'
import DataTable                                from "react-data-table-component";
import DataTableSkeleton                        from 'components/skeleton/dataTableSkeleton';
import DashboardLayout                          from '../layouts/dashboardLayout'
import UserContext                              from '../contexts/userContext'
import BasicInput                               from 'components/inputs/basicInput';
import BasicThemeButton                         from 'components/buttons/basicThemeButton';
import location_icon                            from '../images/location-pin.png';

function KeyLoanHistoryScreen() {

  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const {user} = useContext(UserContext);

  const header = [
    {label : "Visitor Name",  key : 'visitorName'},
    {label : "Company",       key : 'visitorCompany'},
    {label : "Email",         key : 'visitorEmail'},
    {label : "Phone No.",     key : 'visitorPhone'},
    {label : "Tag Name",      key : 'tag'},
    {label : "Key No.",       key : 'keyNumber'},
    {label : "Issue Time",    key : 'issueTime'},
    {label : "Return Time",   key : 'returnTime'},
  ];

const columns = [
    {name: 'Name', selector: row => capitalizeWords(row.visitorName)},
    {name : "Company", selector : row => capitalizeWords(row.visitorCompany)},
    {name : "Tag Name", selector : row => capitalizeWords(row.tag)},
    {name : "Key No.", selector : row => row.keyNumber},
    {name : "Email", selector : row => row.visitorEmail},
    {name : "Phone No.", selector : row => row.visitorPhone},
    {name : "Issue Time", selector : row => row.issueTime},
    {name : "Return Time", selector : row => row.returnTime},
    {name : "Location", button : true, cell : (row) => (
      <img className='location-pin' src={location_icon} onClick={()=> onLocationClick(row.location)} />
    )}
  ];

  const onLocationClick = (location) => {
    if(location[0] && location[1]) window.open(`https://maps.google.com?q=${location[0]},${location[1]}`, '_blank', 'noreferrer');
    else toast.error("No Location Details");
  }

  useEffect(() => {
    setLoading(true);
    getAllKeyLoanData(user.buildingId).then((res) => {
      setData(res.result);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    })
  }, []);

  const onSearchValueChanges = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  }

  return (
    <DashboardLayout pageTitle="Key Loan History">
        <div className="row gap-1">
            <BasicInput  placeholder="Search by Visitor Name or Key Tag" value={searchValue} setValue={setSearchValue} onChange={onSearchValueChanges} />
            <div className="export-data">
              <CSVLink data={data} headers={header} filename="Key_Loan_History.csv">
                <BasicThemeButton title="Export to CSV" />
              </CSVLink>
            </div>
        </div>
        <div className="table-container">
          {loading ?
            <DataTableSkeleton /> :
            <DataTable 
              columns={columns}
              data={data.filter(item => {
                if(searchValue == '') return item
                if(item.visitorName.toLowerCase().includes(searchValue.toLocaleLowerCase()) || item.tag.toLowerCase().includes(searchValue.toLowerCase())) return item
              })}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
              paginationComponentOptions={{
                rowsPerPageText: "Records per page:",
                rangeSeparatorText: "out of"
              }}
            /> 
          }
        </div>
    </DashboardLayout>
  )
}

export default KeyLoanHistoryScreen;