import React from 'react'
import ContentLoader from 'react-content-loader';

function ModalInputSkeleton() {
  return (
    <ContentLoader 
        speed={2}
        width='100%'
        height="200"
        viewBox="0 0 100% 124"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
    >
    <rect x="1" y="14" rx="3" ry="3" width="88" height="10" /> 
    <rect x="1" y="30" rx="3" ry="3" width="50%" height="25" /> 
    <rect x="51%" y="14" rx="3" ry="3" width="88" height="10" /> 
    <rect x="51%" y="30" rx="3" ry="3" width="50%" height="25" /> 
    <rect x="1" y="68" rx="3" ry="3" width="88" height="10" /> 
    <rect x="1" y="84" rx="3" ry="3" width="100%" height="25" />
    <rect x="70%" y="144" rx="3" ry="3" width="30%" height="25" />
  </ContentLoader>
  )
}

export default ModalInputSkeleton