import React, {useState, useEffect, useContext} from 'react'
import { Outlet, useNavigate, Navigate } from 'react-router-dom'
import { currentUser } from './services/authServices';
import {auth, db } from './firebaseConfig';
import {onAuthStateChanged, signInWithEmailAndPassword, signOut  } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import UserContext from './contexts/userContext';

/** Function to redirect to dashboard page if the user is already logged in
**/
function RedirectRoute({children}) {
    const navigate = useNavigate();

    const { isAuthenticated, user } = useContext(UserContext);

    return (
        !isAuthenticated ? children : user.userType === 'contractor' ? <Navigate to='/contractor-dashboard' replace /> : <Navigate to='/dashboard' replace />
    )
    
}

export default RedirectRoute;