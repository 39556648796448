import React, { useContext } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import UserContext from './contexts/userContext';

function ManagerRoute({children}) {
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    return (
        user.userType == 'manager' ? children : <Navigate to='/key-registry' replace />
    )
    
}

export default ManagerRoute;