import React, {useState, useEffect, useContext}     from 'react';
import { useNavigate }                              from 'react-router-dom';
import UserContext                                  from 'contexts/userContext'
import UserService                                  from 'services/userServices';
import { getBuildingNameById }                      from "services/buildingServices";
import { checkIfEmpty, isValidEmail }               from 'functions/functions';
import { showErrorToast, showSuccessToast }         from 'utils/toasts';
import { invitationEmailTemplate }                  from 'utils/invitationEmail';

const useManageUser = () => {

    const {user} = useContext(UserContext);

    const { getUserData, getAllUserData, getAllContractorUsersData, addNewUser, checkIfUserExists, registerNewUser, getPendingUsers } = UserService();

    const navigate = useNavigate();

    const [data, setData]                                           = useState([]);
    const [filteredData, setFilteredData]                           = useState([]);
    const [searchValue, setSearchValue]                             = useState('');
    const [selectedOption, setSelectedOption]                       = useState('registered');
    const [selectedRoleFilter, setSelectedRoleFilter]               = useState('registered');
    const [managerBuildings, setManagerBuildings]                   = useState([]);
    const [modalIsOpen, setIsOpen]                                  = useState(false);
    const [invitedUsersModalStatus, setInvitedUsersModalStatus]     = useState(false);
    const [pendingUsers, setPendingUsers]                           = useState([]);        
    const [addUserSelectedBuilding, setAddUserSelectedBuilding]     = useState('');
    const [addUserSelectedRole, setAddUserSelectedRole]             = useState('');
    const [addUserName, setAddUserName]                             = useState('');
    const [addUserEmail, setAddUserEmail]                           = useState('');
    const [addUserPassword, setAddUserPassword]                     = useState('');
    const [errorStatus, setErrorStatus]                             = useState(false);
    const [errorMessage, setErrorMessage]                           = useState('');
    const [loading, setLoading]                                     = useState(true);
    const [modalLoading, setModalLoading]                           = useState(false);
    const [invitedUsersModalLoading, setInvitedUsersModalLoading]   = useState(false);

    const roleOptions = [{label: 'All User Roles', value: 'All'}, {label: 'Reception Users', value: 'Reception User'}, {label: 'Guest Users', value: 'Guest User'}, {label: 'Contractor Users', value: 'Contractor'}, {label: 'Managers', value: 'Manager'}];

    const addUserRoleOptions = [{label: 'Reception User', value: 'reception-user'}, {label: 'Guest User', value: 'guest-user'}, {label: 'Contractor', value: 'contractor'}];

    const header = [
        {label : "Name",    key : "fullName"},
        {label : "Role",    key : "accountType"},
        {label : "Email",   key : "email"},
        {label : "Status",  key : "status"}
    ];

    const columns = [
        {name: 'Name', selector: row => row.fullName,},
        {name : "Role", cell : (row) => (
            <p className={`badge ${row.accountType == 'Reception User' ? 'reception-badge' : row.accountType == 'Guest User' ? 'guest-badge' : row.accountType == 'Contractor' ? 'contractor-badge' : 'manager-badge'}`}>{row.accountType}</p>
        )},
        {name : "Email", selector : row => row.email},
        {name : "Status", cell : (row) => (
            <p className={`badge ${row.status == 'Active' ? 'active-badge' : 'in-active-badge'}`}>{row.status}</p>
        )},
    ];

    const invitedUsers = [
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Building Access',
            selector: row => row.building,
        },
        {
            name: 'Reference Key',
            selector: row => row.refKey,
        },
        // {
        //     name: 'Action',
        //     cell : (row) => (
        //         <p className={`badge ${row.action == 'return_key' ? 'reception-badge' : 'guest-badge'}`}>{row.action == 'return_key' ? 'Return' : 'Issue'}</p>
        //     )
        // },
        // {
        //     name: 'Timestamp',
        //     selector: row => row.timestamp,
        // },
      ];
    
    
      const invitedUsersData = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
      ]

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        const response = await Promise.all([getAllUserData(), getAllContractorUsersData()]);

        const combinedData = [...response[0], ...response[1]];
        const sortedData = combinedData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

        setData(sortedData);
        setFilteredData(sortedData);
        setTimeout(()=> {
            setLoading(false);
        },600)
    }

    const rowClick = (e) => {
        const userId = e.userId;
        navigate(`/manage-users/${userId}`);
    }

    const onRoleFilterSelected = (e) => {
        const selectedOption = e.value;
        setSelectedRoleFilter(selectedOption);
        if(selectedOption != 'All') {
            const filteredResult = data.filter((data) => {
                return data.accountType == selectedOption;
            })
            setFilteredData(filteredResult);
        } else {
            setFilteredData(data);
        }
        
    }

    const addNewUserModal = async () => {
        setModalLoading(true);
        const managerBuildings = await getManagerApprovedBuildings();
        const buildingOptions = [{label : 'All Buildings', value : '_all' }];
        managerBuildings.forEach((building) => {
            buildingOptions.push({
                'label' : building.name.name,
                'value' : building.id
            })
        })

        setManagerBuildings(buildingOptions);
        setIsOpen(true);

        setTimeout(() => {
            setModalLoading(false);
        }, 500)
    }

    const onBuildingSelect = (e) => {
        setAddUserSelectedBuilding(e.value);
    }

    const onRoleSelect = (e) => {
        setAddUserSelectedRole(e.value);
    }

    const onAddUserSubmit = async () => {
        setModalLoading(true);
        let errorStatusObj = {};
        let errorMessageObj = {};

        if(checkIfEmpty(addUserSelectedBuilding)) {
            errorStatusObj.building = true;
            errorMessageObj.building = 'Please Select a Building';
        };

        if(checkIfEmpty(addUserSelectedRole)) {
            errorStatusObj.role = true;
            errorMessageObj.role = 'Please Select User Role';
        }

        let emailAddress = addUserEmail.trim().toLowerCase();

        if(checkIfEmpty(emailAddress)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Please Enter User Email';
        }
        else if(!isValidEmail(emailAddress)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Please Enter a valid Email';
        } 
        else if(await checkIfUserExists(emailAddress)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'User Exists Already';
        }

        if(addUserSelectedRole === 'guest-user') {
            if(checkIfEmpty(addUserName)) {
                errorStatusObj.name = true;
                errorMessageObj.name = 'Please Enter Name';
            }

            if(checkIfEmpty(addUserPassword)) {
                errorStatusObj.password = true;
                errorMessageObj.password = 'Please Enter Password';
            }
        }

        if(addUserSelectedRole === 'guest-user') {

            if(!errorStatusObj.building && !errorStatusObj.role && !errorStatusObj.name && !errorStatusObj.email && !errorStatusObj.password) {
                const userData = {
                    accountType : 'guest',
                    approvedBuildings : [addUserSelectedBuilding],
                    fullName : addUserName,
                    manager : user.userId,
                    refKey : 'GUESTREF',
                }

                try {

                    const data = {
                        'name' : addUserEmail,
                        'email_address' : addUserEmail,
                        'subject' : 'TheKeyPower Invitation',
                        'password' : addUserPassword
                    }
                    const emailRequest = await fetch(`https://api.thekeypower.co.uk/mail/guest-invitation`, {
                        method : 'POST',
                        headers : {
                            "Content-Type" : "application/json",
                            "Authorization" : "ha5Gta5a7na098jlvYHnjashdiIUhgaiudbgIOIUH876576asdbsdcsdchiu4a65s4d7stgd7c6s",
                        },
                        body : JSON.stringify(data)
                    });

                    const emailResponse = await emailRequest.json();

                    if(emailResponse.statusCode === 200) {

                        const response = await registerNewUser(addUserEmail, addUserPassword, userData);

                        if(response) {
                            showSuccessToast('Guest User Registered Successfully');
                            setErrorStatus('');
                            setErrorMessage('');
                            setAddUserEmail('');
                            setAddUserPassword('');
                            setAddUserSelectedBuilding('');
                            setAddUserSelectedRole('');
                            fetchUserData();
                            setIsOpen(false);
                        }
                    } else {
                        showErrorToast('Something Went Wrong. Try Again Later.')
                    }

                } catch(err) {
                    showErrorToast('Something Went Wrong. Try Again Later.')
                }

                
            }

        } else {

            if(!errorStatusObj.building && !errorStatusObj.role && !errorStatusObj.email) {
                const response = await addNewUser(addUserSelectedBuilding, emailAddress, addUserSelectedRole);
                if(response.status) {
                    try {
                        // const messageTemplate = await invitationEmailTemplate(emailAddress, response.refKey);
                        const data = {
                            'name' : addUserEmail,
                            'email_address' : addUserEmail,
                            'subject' : 'TheKeyPower Invitation',
                            'reference_key' : response.refKey,
                        }
                        const emailRequest = await fetch(`https://api.thekeypower.co.uk/mail/reception-invitation`, {
                            method : 'POST',
                            headers : {
                                "Content-Type" : "application/json",
                                "Authorization" : "ha5Gta5a7na098jlvYHnjashdiIUhgaiudbgIOIUH876576asdbsdcsdchiu4a65s4d7stgd7c6s",
                            },
                            body : JSON.stringify(data)
                        });

                        const emailResponse = await emailRequest.json();

                        if(emailResponse.statusCode === 200) {
                            showSuccessToast('User Invited Successfully');
                            setErrorStatus('');
                            setErrorMessage('');
                            setAddUserEmail('');
                            setAddUserSelectedBuilding('');
                            setAddUserSelectedRole('');
                            setIsOpen(false);
                        }

                    } catch(err) {
                        showErrorToast('Something Went Wrong');
                    }
                }
            }
        }

        setErrorStatus(errorStatusObj);
        setErrorMessage(errorMessageObj);

        setModalLoading(false);
    }

    function closeModal() {
        setErrorStatus('');
        setErrorMessage('');
        setAddUserEmail('');
        setAddUserSelectedBuilding('');
        setAddUserSelectedRole('');
        setIsOpen(false);
    }

    const openInvitedUsersModal = async () => {
        setInvitedUsersModalLoading(true);
        setInvitedUsersModalStatus(true);

        const response = await getPendingUsers();

        const pendingUsersList = await Promise.all(
            response.emails.map(async (value, index) => {

                let email = response.emails[index];
                let building = response.buildings[index] !== '_all' ? await getBuildingNameById(response.buildings[index]) : {'name' : 'All Buildings'};
                let refKey = response.refKeys[index];

                return {
                    'email' : email,
                    'building' : building.name,
                    'refKey' : refKey,
                }
            })
        );

        setPendingUsers(pendingUsersList);

        setTimeout(() => {
            setInvitedUsersModalLoading(false);
        }, 500);
    }

    const closeInvitedUsersModal = () => {
        setInvitedUsersModalStatus(false);
    }


    const getManagerApprovedBuildings = async () => {
        const approvedBuildingList = await Promise.all(
            user.approvedBuildings.map(async(building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName,
                    'id' : building
                };
            })
        );
        
        return approvedBuildingList;
    }

    return {
        data,
        setData,
        filteredData, 
        setFilteredData,
        searchValue,
        setSearchValue,
        selectedOption, 
        setSelectedOption,
        selectedRoleFilter, 
        setSelectedRoleFilter,
        onRoleFilterSelected,
        modalIsOpen, 
        setIsOpen,
        closeModal,
        invitedUsers,
        invitedUsersData,
        invitedUsersModalStatus, 
        setInvitedUsersModalStatus,
        invitedUsersModalLoading,
        openInvitedUsersModal,
        closeInvitedUsersModal,
        pendingUsers,
        roleOptions,
        header,
        columns,
        rowClick,
        addNewUserModal,
        getManagerApprovedBuildings,
        managerBuildings, 
        setManagerBuildings,
        addUserRoleOptions,
        onBuildingSelect,
        onRoleSelect,
        addUserSelectedBuilding, 
        setAddUserSelectedBuilding,
        addUserSelectedRole, 
        setAddUserSelectedRole,
        addUserName,
        setAddUserName,
        addUserEmail, 
        setAddUserEmail,
        addUserPassword, 
        setAddUserPassword,
        onAddUserSubmit,
        errorStatus,
        errorMessage,
        loading,
        setLoading,
        modalLoading, 
        setModalLoading
    }

}

export default useManageUser;


