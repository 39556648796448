import React from 'react'
import ContentLoader from 'react-content-loader';

function ListSkeleton() {
  return (
    <ContentLoader
        width="100%"
        height={350}
        viewBox="0 0 100% 350"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
    >
    <rect x="55" y="52" rx="6" ry="6" width="100%" height="15" />
    <circle cx="25" cy="60" r="15" />
    <rect x="55" y="105" rx="6" ry="6" width="100%" height="15" />
    <circle cx="25" cy="113" r="15" />
    <rect x="55" y="158" rx="6" ry="6" width="100%" height="15" />
    <circle cx="25" cy="166" r="15" />
    <rect x="55" y="211" rx="6" ry="6" width="100%" height="15" />
    <circle cx="25" cy="219" r="15" />
    <rect x="55" y="263" rx="6" ry="6" width="100%" height="15" />
    <circle cx="25" cy="271" r="15" />
  </ContentLoader>
  )
}

export default ListSkeleton