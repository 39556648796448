import { useContext } from 'react';
import { initializeTemporaryFirebaseApp } from 'tempFirebaseConfig';
import { auth, db } from '../firebaseConfig';
import { collection, query, where, getDocs, orderBy, doc, getDoc, updateDoc, arrayRemove, arrayUnion, setDoc} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, deleteUser, signOut, sendPasswordResetEmail } from 'firebase/auth';
import UserContext from 'contexts/userContext';
import { getBuildingNameById } from './buildingServices';
import moment from 'moment';
import { generateReferenceKey, checkReferenceKeyExists} from 'functions/functions';
import { Timestamp } from 'firebase/firestore';
import { showErrorToast } from 'utils/toasts';

const UserService = () => {

    const {user} = useContext(UserContext);

    const getAllUserData = async () => {
        
        try {
            const docRef = collection(db, "users");
            let userQuery = query(docRef, orderBy('startDate', 'desc'), 
                            where("manager", "==", user.userId));

            const querySnapshot = await getDocs(userQuery);

            let resultArray = await Promise.all(querySnapshot.docs.map(async (doc) => {
                const user = doc.data();

                const buildingName = user.currentBuilding ? await getBuildingNameById(user.currentBuilding) : 'Not Logged In';
                return {
                    userId: doc.id,
                    accountType: user.accountType === 'user' ? 'Reception User' : user.accountType === 'guest' || user.accountType === 'contractor-guest' ? 'Guest User' : user.accountType === 'contractor' ? 'Contractor' : 'Manager',
                    status: user.approvalFlag ? 'Active' : 'Inactive',
                    approvedBuildings: user.approvedBuildings,
                    currentBuilding: user.currentBuilding,
                    currentBuildingName: buildingName.name ? buildingName.name : buildingName,
                    displayPhotoUrl: user.displayPhotoUrl,
                    email: user.email,
                    fullName: user.fullName,
                    manager: user.manager,
                    refKey: user.refKey,
                    createdOn: moment(user.startDate.toDate()).format('MM/DD/YYYY hh:mm a'),
                };
            }));
            
            return resultArray;
        } catch(err) {
        }
        
    }

    const getAllContractorUsersData = async () => {
        
        try {
            const docRef = collection(db, "contractors");
            let userQuery = query(docRef, orderBy('startDate', 'desc'), 
                            where("manager", "==", user.userId));

            const querySnapshot = await getDocs(userQuery);

            let resultArray = await Promise.all(querySnapshot.docs.map(async (doc) => {
                const user = doc.data();

                const buildingName = user.currentBuilding ? await getBuildingNameById(user.currentBuilding) : 'Not Logged In';
                return {
                    userId: doc.id,
                    accountType: user.accountType === 'contractor-guest' ? 'Guest User' : 'Contractor',
                    status: user.approvalFlag ? 'Active' : 'Inactive',
                    approvedBuildings: user.approvedBuildings,
                    currentBuilding: user.currentBuilding,
                    currentBuildingName: buildingName.name ? buildingName.name : buildingName,
                    displayPhotoUrl: user.displayPhotoUrl,
                    email: user.email,
                    fullName: user.fullName,
                    manager: user.manager,
                    refKey: user.refKey,
                    createdOn: moment(user.startDate.toDate()).format('MM/DD/YYYY hh:mm a'),
                };
            }));
            
            return resultArray;
        } catch(err) {
        }
        
    }

    // Function to get all users added by a contractor
    const getContractorGuestUser = async () => {
        let userArray = [];
        try {
            const docRef = collection(db, "contractors");
            let userQuery = query(docRef, orderBy('startDate', 'desc'), 
                            where("accountType", '==', 'contractor-guest'));
            
            const querySnapshot = await getDocs(userQuery);
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if(data.manager === user.manager) {
                    let contractorIds = [];

                    for(const [key, value] of Object.entries(data.approvedBuildings[user.manager])) {
                        contractorIds.push(key);
                    }

                    if(contractorIds.includes(user.userId)) {
                        let obj = {
                            ...data,
                            userId : doc.id
                        }
    
                        userArray.push(obj);
                    }

                    
                }
                
            });
            return userArray;
        } catch(err) {

        }
    }


    const getUserData = async (userId) => {
        try {
            const userDocRef = doc(db, "users", userId);
            const userSnapshot = await getDoc(userDocRef);
            if(userSnapshot.exists()) {
                const user = userSnapshot.data();
                return user;
            } else {

                const userDocRef = doc(db, "contractors", userId);
                const userSnapshot = await getDoc(userDocRef);
                if(userSnapshot.exists()) {
                    const user = userSnapshot.data();
                    return user;
                } else {
                    throw new Error('User Not Found');
                }
            }
        } catch(err) {

        }
    }

    const changeUserStatus = async (userId, data, status) => {
        try {
            if(data.accountType == 'contractor' || data.accountType == 'contractor-guest') {
                const userDocRef = doc(db, "contractors", userId);
                await updateDoc(userDocRef, {
                    approvalFlag : Boolean(status)
                })
    
                return {
                    status : true,
                    message : "Updated Successfully"
                }
            } else {
                const userDocRef = doc(db, "users", userId);
                await updateDoc(userDocRef, {
                    approvalFlag : Boolean(status)
                })
    
                return {
                    status : true,
                    message : "Updated Successfully"
                }
            }
            
        } catch(err) {
            return {
                status : false,
                message : err
            }
        }
    }

    const getPendingUsers = async () => {
        try {
            const pendingUsersRef = doc(db, "account", user.email);
            const pendingUsersSnapshot = await getDoc(pendingUsersRef);
            if(pendingUsersSnapshot.exists()) {
                const user = pendingUsersSnapshot.data();
                const data = {
                    'emails' : user.pendingEmailAddresses,
                    'buildings' : user.pendingBuildingAssignment,
                    'refKeys' : user.pendingRefKeys
                }
                return data;
            } else {
                throw new Error('User Not Found');
            }
        } catch(err) {
        }
    }


    const getUserEventLogs = async (userId) => {
        try {
            const logRef = collection(db, "audit");
            let logQuery = query(logRef, orderBy('timestamp', 'desc'), 
                           where("user", "==", userId));
            
            const querySnapshot = await getDocs(logQuery);
            if(querySnapshot.size > 0) {
                let resultArray = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const log = doc.data();
                    const keyDetails = await getKeyDetailsById(log.data);
                    const buildingName = await getBuildingNameById(log.buildingID);
                    
                    return {
                        action: log.action,
                        buildingID : log.buildingID,
                        buildingName : buildingName.name,
                        keyName : keyDetails.data.tag,
                        keyNumber : keyDetails.data.number,
                        timestamp: moment(log.timestamp.toDate()).format('MM/DD/YYYY hh:mm a'),
                    };
                    
                }));
                return resultArray;
            } else {
                return [];
            }
            
        } catch(err) {
        }
    }


    const handlePasswordReset = async (email) => {
      
        try {
          // Send a password reset email to the provided email
          await sendPasswordResetEmail(auth, email);
    
          return {
            'status' : true,
          }

        } catch (error) {
            console.error('Error sending password reset email:', error.message);
            let message;
            if(error.code == 'auth/user-not-found') {
                message = 'User Not Found. Please Check Email.'
            } else {
                message = 'Something Went Wrong'
            }

            return {
                'status' : false,
                'message' : message
            }
        }
    }; 


        const getKeyDetailsById = async (id) => {
            const docRef = doc(db, "allKeys", id);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()) {
                const data = docSnap.data();
                return {'status' : true, 'data' : data};
            } else {
                return {'status' : false ,'message' : 'Building Not Exists'};
            }
        }



        const revokeBuildingAccess = async (userId, data, revokedBuildings) => {
            if(data.accountType == 'contractor') {
                try {
                    const userDocRef = doc(db, "contractors", userId);
                    await updateDoc(userDocRef, {
                        [`approvedBuildings.${user.userId}`]: arrayRemove(...revokedBuildings)
                    });
    
                    return true;
    
                } catch(err) {
                }

            } else if(data.accountType == 'contractor-guest') {

                const userApprovedBuildings = data.approvedBuildings[user.userId];
                try {
                    const userDocRef = doc(db, "contractors", userId);
                    const updatePromises = [];

                    revokedBuildings.forEach((building) => {
                        for (const [id, buildingArray] of Object.entries(userApprovedBuildings)) {
                            if (buildingArray.includes(building)) {
                                const promise = updateDoc(userDocRef, {
                                    [`approvedBuildings.${user.userId}.${id}`]: arrayRemove(building)
                                });
                                updatePromises.push(promise);
                            }
                        }
                    });

                    await Promise.all(updatePromises);

                    return true;
    
                } catch(err) {
                }
            } else {
                try {
                    const userDocRef = doc(db, "users", userId);
                    await updateDoc(userDocRef, {
                        ['approvedBuildings'] : arrayRemove(...revokedBuildings)
                    });
    
                    return true;
    
                } catch(err) {
                }

            }
            
        }


        const addBuildingAccess = async (userId, data, accessBuildings) => {

            try {
                if(data.accountType == 'contractor') {
                    const userDocRef = doc(db, "contractors", userId);
                    await updateDoc(userDocRef, {
                        [`approvedBuildings.${user.userId}`]: arrayUnion(...accessBuildings)
                    });
    
                    return true;

                } else if(data.accountType == 'contractor-guest') {
                    const userDocRef = doc(db, "contractors", userId);
                    await updateDoc(userDocRef, {
                        [`approvedBuildings.${user.userId}.manager`]: arrayUnion(...accessBuildings)
                    });
    
                    return true;
                } else {
                    const userDocRef = doc(db, "users", userId);
                    await updateDoc(userDocRef, {
                        ['approvedBuildings'] : arrayUnion(...accessBuildings)
                    });
    
                    return true;
                }
                

            } catch(err) {

            }
        }

        // Function to update user details
        const updateUser = async (userId, data) => {
            try {
                const userDocRef = doc(db, "users", userId);
                await updateDoc(userDocRef, data)
            } catch(err) {

                showErrorToast('Something Went Wrong');
            }
        }

        
        // Function to update user details
        const updateContractorUser = async (userId, data) => {
            try {
                const userDocRef = doc(db, "contractors", userId);
                await updateDoc(userDocRef, data)
            } catch(err) {

                showErrorToast('Something Went Wrong');
            }
        }


        const addNewUser = async (buildingId, emailAddress, role) => {
            try {
                const refKey = await createNewReferenceKey();
                const userDocRef = doc(db, "account", user.email);
                const userSnapshot = await getDoc(userDocRef);

                if(userSnapshot.exists()) {
                    const user = userSnapshot.data();
                    const pendingBuildingAssignmentArray = user.pendingBuildingAssignment;
                    pendingBuildingAssignmentArray.push(buildingId);

                    try {

                        await updateDoc(userDocRef, {
                            pendingBuildingAssignment : pendingBuildingAssignmentArray,
                            pendingEmailAddresses : arrayUnion(emailAddress),
                            pendingRefKeys : arrayUnion(refKey),
                            pendingRoles : arrayUnion(role)
                        });

                        return {
                            'status': true,
                            'refKey' : refKey
                        };

                    } catch(err) {  
                    }
                }

            } catch(err) {
            }
     
        }

        const registerNewUser = async (email, password, userData) => {

            const tempApp   = initializeTemporaryFirebaseApp();
            const tempAuth  = getAuth(tempApp);
            
            try {

                const response = await createUserWithEmailAndPassword(tempAuth, email, password).then(async (userCredential) => {
                const regUser = userCredential.user;

                    try {

                        if(userData.accountType === 'contractor-guest') {

                            const userRef = doc(db, "contractors", regUser.uid);
    
                        const newUser = {
                            'accountType' : userData.accountType,
                            'approvalFlag' : true,
                            approvedBuildings : {
                                [user.manager] : {
                                    [user.userId] : userData.approvedBuildings
                                }
                            },
                            'currentBuilding' : '',
                            'displayPhotoUrl' : '',
                            'email' : email,
                            'fullName' : userData.fullName,
                            'manager' : userData.manager,
                            'refKey' : userData.refKey,
                            'startDate' : Timestamp.fromDate(new Date())
                        }
    
                        await setDoc(userRef, newUser);
    
                        return true;

                        } else {

                            const userRef = doc(db, "users", regUser.uid);
    
                        const newUser = {
                            'accountType' : userData.accountType,
                            'approvalFlag' : true,
                            'approvedBuildings' : userData.approvedBuildings,
                            'currentBuilding' : '',
                            'displayPhotoUrl' : '',
                            'email' : email,
                            'fullName' : userData.fullName,
                            'manager' : userData.manager,
                            'refKey' : userData.refKey,
                            'startDate' : Timestamp.fromDate(new Date())
                        }

                        if(userData.accountType == "contractor-guest") {
                            newUser.contractor = userData.contractor;
                        }
    
                        await setDoc(userRef, newUser);
    
                        return true;

                        }

                        
    
                    } catch(err) {
                        if(auth) {
                            await deleteUser(auth, regUser.uid);
                        } else {
                            await deleteUser(tempAuth, regUser.uid);
                        }
                        
                        return false;
                    }

                });
                
                return response;

            } catch(err) {
                return false;

            }
            
        }


        const createNewUser = async (email, password) => {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                return {
                    status : true,
                    message : 'user created',
                    uId : userCredential.user.uid,
                }

            } catch(err) {
                let message;
                if(err.code == 'auth/weak-password') {
                    message = 'Weak Password.'
                } else if(err.code == 'auth/email-already-in-use') {
                    message = 'Email Already Exists';
                } else {
                    message = 'Something Went Wrong'
                }
                
                return {
                    status : false,
                    message : message
                }
            }
        }


        const deleteUserAuth = async () => {
            try {
                const user = auth.currentUser;
                await deleteUser(user);
                return {
                    status : true
                }

            } catch(err) {
                return {
                    status : false,
                    message : err
                }
            }
        }


        const addUserToFireStore = async (uId, userData) => {
            try {
                let userRefCollection;
                if(userData.accountType == 'contractor') userRefCollection = 'contractors'
                else userRefCollection = 'users';

                const userRef = doc(db, userRefCollection, uId);
                const newUser = {
                    // 'accountType' : userData.accountType,
                    // 'approvalFlag' : true,
                    // 'approvedBuildings' : userData.approvedBuildings,
                    // 'currentBuilding' : '',
                    // 'displayPhotoUrl' : '',
                    // 'email' : userData.email,
                    // 'fullName' : userData.fullName,
                    // 'manager' : userData.manager,
                    // 'refKey' : userData.refKey,
                    // 'startDate' : Timestamp.fromDate(new Date())

                        accountType         : userData.accountType == 'contractor' ? 'contractor' : 'user',
                        approvedBuildings   : {},
                        approvalFlag        : true,
                        displayPhotoUrl     : '',
                        email               : userData.email,
                        currentBuilding     : '',
                        fullName            : userData.fullName,
                        manager             : userData.manager,
                        refKey              : userData.refKey,
                        startDate           : Timestamp.fromDate(new Date()) 
                }

                if(userData.accountType == 'contractor') {
                    newUser['approvedBuildings'][`${userData.manager}`] = userData.approvedBuildings;
                } else {
                    newUser['approvedBuildings'] = userData.approvedBuildings;
                }



                await setDoc(userRef, newUser);

                return {
                    status : true
                }

            } catch(err) {
                return {
                    status : false,
                    message : err
                }
            }
        }

        
        const checkIfUserExists = async (emailAddress) => {
            try {
                const userCollection = collection(db, 'users');
                const userQuery = query(userCollection, where('email', '==', emailAddress));
                const querySnapshot = await getDocs(userQuery);

                const contractorCollection = collection(db, 'contractors');
                const contractorQuery = query(contractorCollection, where('email', '==', emailAddress));
                const contractorSnapshot = await getDocs(contractorQuery);

                
                return !(querySnapshot.empty && contractorSnapshot.empty);

            } catch(err) {
            }
        }


        const createNewReferenceKey = async () => {
            let referenceKey = generateReferenceKey();
            let exists = await checkReferenceKeyExists(referenceKey, user.email);
            while (exists) {
              referenceKey = generateReferenceKey();
              exists = await checkReferenceKeyExists(referenceKey);
            }
            return referenceKey;
        };


        const checkEmailReferenceValidation = async(email, refKey, role) => {
            try { 
                const accountRef                = collection(db, 'account');
                const accountQuery              = query(accountRef, where('pendingEmailAddresses', 'array-contains', email));
                const accountQuerySnapshot      = await getDocs(accountQuery);

                if(!accountQuerySnapshot.empty) {
                    const accountEmail      = accountQuerySnapshot.docs[0].id;
                    const invitationData    = accountQuerySnapshot.docs[0].data();

                    const pendingEmailAddresses         = invitationData.pendingEmailAddresses;
                    const pendingReferenceKeys          = invitationData.pendingRefKeys;
                    const pendingBuildingAssignments    = invitationData.pendingBuildingAssignment;
                    const pendingRoles                  = invitationData.pendingRoles;

                    const emailIndex        = pendingEmailAddresses.indexOf(email);
                    const referenceKey      = pendingReferenceKeys[emailIndex];
                    const assignedBuilding  = pendingBuildingAssignments[emailIndex];
                    const assignedRole      = pendingRoles[emailIndex];

                    let managerId;

                    // Checking Refernce Key
                    if(refKey === referenceKey) {
                        
                        if(assignedRole === role) {

                            // Get the userId of the manager account
                            try {
                                const userRef           = collection(db, 'users');
                                const userQuery         = query(userRef, where('email', '==', accountEmail));
                                const userQuerySnapshot = await getDocs(userQuery);

                                // If user exists
                                if(!userQuerySnapshot.empty) {
                                    managerId = userQuerySnapshot.docs[0].id;
                                } else {
                                    // If user email does not exists
                                    throw 'account-does-not-exists';
                                }

                            } catch(err) {
                                return {
                                    status : false,
                                    message : err
                                }
                            }

                            // Return Success Message and Data
                            return {
                                status : true,
                                data : {
                                    managerId : managerId,
                                    managerEmail : accountEmail,
                                    assignedBuilding : assignedBuilding,
                                    index : emailIndex,
                                    pendingBuildingAssignments : pendingBuildingAssignments,
                                    pendingEmailAddresses : pendingEmailAddresses,
                                    pendingReferenceKeys : pendingReferenceKeys
                                }
                            }

                        } else {
                            // If email and reference key does not match
                            throw 'invalid-role';
                        }

                        

                    } else {
                        // If email and reference key does not match
                        throw 'email-reference-does-not-match';
                    }

                } else {
                    // If the email not present in pendingEmailAddress
                    throw 'not-invited';
                }

            } catch (err) {
                return {
                    status : false,
                    message : err
                }
            }

        }

        const signOutUser = async () => {
            try {
                await signOut(auth);
                return true;
              } catch (error) {
                console.error('Error signing out:', error);
                return false;
              }
        }

        const removePendingData = async (indexToRemove, managerId) => {
            try {
                const userDocRef = doc(db, "account", managerId);
                const userSnapshot = await getDoc(userDocRef);
                if(userSnapshot.exists()) {
                    const user = userSnapshot.data();
                    const pendingBuildingAssignmentArray = user.pendingBuildingAssignment.filter((_, index) => index !== indexToRemove);
                    const pendingEmailAddressArray = user.pendingEmailAddresses.filter((_, index) => index !== indexToRemove);
                    const pendingRefKeysArray = user.pendingRefKeys.filter((_, index) => index !== indexToRemove);
                    const pendingRoles = user.pendingRoles.filter((_, index) => index !== indexToRemove);

                    try {

                        await updateDoc(userDocRef, {
                            pendingBuildingAssignment : pendingBuildingAssignmentArray,
                            pendingEmailAddresses : pendingEmailAddressArray,
                            pendingRefKeys : pendingRefKeysArray,
                            pendingRoles : pendingRoles
                        });

                        return {
                            'status': true,
                        };

                    } catch(err) {  
                        return {
                            'status' : false
                        }
                    }
                }

            } catch(err) {
                return {
                    'status' : false
                }
            }
        }

        const getUserApprovedBuildings = async (userId) => {
            try {

                const userDocRef = doc(db, 'users', userId);

                const userSnapshot = await getDoc(userDocRef);

                if(userSnapshot.exists()) {
                    const user = userSnapshot.data();
                    const approvedBuildings = user.approvedBuildings;

                    return {
                        status : true,
                        data : approvedBuildings
                    }

                } else {
                    throw 'user-not-found';
                }

            } catch(err) {
                return {
                    status : false,
                    message : err
                }
            }

        }


    return {
        getAllUserData,
        getAllContractorUsersData,
        getContractorGuestUser,
        getUserData,
        revokeBuildingAccess,
        addBuildingAccess,
        addNewUser,
        checkIfUserExists,
        registerNewUser,
        getUserEventLogs,
        checkEmailReferenceValidation,
        getUserApprovedBuildings,
        createNewUser,
        deleteUserAuth,
        addUserToFireStore,
        signOutUser,
        changeUserStatus,
        removePendingData,
        handlePasswordReset,
        getPendingUsers,
        updateUser,
        updateContractorUser
    }

}

export default UserService;