import  { createContext, useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from 'firebaseConfig';

const UserContext = createContext();

const userFromStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : '';

export function UserProvider({children}) {
    const [user, setUser] = useState(userFromStorage);
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if(user) {
            return user?.userId ? true : false;
        } else {
            return false;
        }
        
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(isAuthenticated) fetchUserdetails();
    },[]);

    const fetchUserdetails = async () => {
        setLoading(true);
        try {
        const userId = user.userId;
        let collection;

        if(user.userType === 'contractor') collection = 'contractors';
        else collection = 'users';

        const docRef = doc(db, collection, userId);
        const docSnap = await getDoc(docRef);
        let userArray;
        if(docSnap.exists()) {
            const userData = docSnap.data();
            userData.userId = userId;
            let resposnse =  {'status' : true, data: userData}
            const responseData = resposnse.data;
            userArray = {
                email : responseData.email,
                approvedBuildings : responseData.approvedBuildings,
                manager : responseData.manager,
                userId : responseData.userId,
                userName : responseData.fullName,
                userType : responseData.accountType,
            }
            if(responseData.accountType == 'manager' || responseData.accountType == 'user') {
                userArray.buildingId = userFromStorage.buildingId;
                userArray.buildingName = userFromStorage.buildingName;
            }
            

        }
        setUser(userArray);
        } catch(err) {
        }
        setLoading(false);
    }

    const saveUserDetails = async (user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    
    return(
        <UserContext.Provider value={{user, saveUserDetails, isAuthenticated, setIsAuthenticated}}>{children}</UserContext.Provider>
    )
}

export default UserContext;