import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { currentUser } from './services/authServices';
import {auth, db } from './firebaseConfig';
import {onAuthStateChanged, signInWithEmailAndPassword, signOut  } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import UserContext from './contexts/userContext';

function AuthRoute({children}) {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(UserContext);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(!user) {
                setIsAuthenticated(false);
                navigate('/');
            }
        })
    }, [])
    return children;
    
}

export default AuthRoute;