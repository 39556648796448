import {auth, db } from '../firebaseConfig';
import {onAuthStateChanged, signInWithEmailAndPassword, signOut  } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const signIn = async (emailAddress, password) => {
    let resposnse;
    await signInWithEmailAndPassword(auth, emailAddress, password).then(async (userCredential) => {
        const userId = userCredential.user.uid;
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            const userData = docSnap.data();
            userData.userId = userId;
            resposnse =  {'status' : true, data: userData}
        } else {
          const contractorDocRef = doc(db, "contractors", userId);
          const contractorDocSnap = await getDoc(contractorDocRef);
          if(contractorDocSnap.exists()) {
            const userData = contractorDocSnap.data();
            userData.userId = userId;
            resposnse =  {'status' : true, data: userData}
          } else {
            resposnse = {'status' : false}
          }
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        resposnse =  {'status' : false, errorCode : errorCode, errorMessage : errorMessage}
      });
      return resposnse;
}

export const userSignOut = async () => {
  const resp = signOut(auth).then(() => {
    return true;
  }).catch((error) => {
    return error;
  })
  return resp;
}


export const currentUser = async () => {
  onAuthStateChanged(auth, (user) => {
    return user;
  })
}