import React from 'react'

// Input Component with Input Label
// label:           Label to display
// placeholder:     Placeholder to display
// type:            type = password / text
// onChange:        Function to execute when input changes
// value:           Value of input
// isError:         Error Status of Input Field
// errorMessage:    Message to display on error

function LabelInput({label, placeholder, type, value, id, onChange, mandatory, disabled=false, classNames, isError, errorMessage}) {
  return (
    <div className={`label-input ${classNames}`}>
        <label htmlFor={id}>{label}{mandatory ? <span className='required-input'>*</span> : '' }</label>
        <input 
          className= {isError ? 'error-outline' : ''} 
          id={id}
          disabled={disabled} 
          placeholder={placeholder} 
          value={value}
          type={type == 'password' ? 'password' : 'text'} 
          onChange={onChange}  
        />
        <p className={`error-message ${isError ? 'active' : ''}`}>{errorMessage ? errorMessage : 'Error'}</p>
    </div>
  )
}

export default LabelInput

// Stylesheet: sass/components/inputs/_input.scss