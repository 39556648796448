import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import LabelInput from '../../components/inputs/labelInput'
import BasicThemeButton from 'components/buttons/basicThemeButton';
import Logo from 'images/The Key Power Logo.png';
import useRegisterUser from './use-register-user';
import SelectInput from 'components/inputs/selectInput';

function RegisterUserScreen() {

    const {
        role, setRole,
        fullName,
        setFullName,
        emailAddress,
        setEmailAddress,
        referenceKey,
        setRefernceKey,
        password,
        setPassword,
        onRegisterUserClick,
        errorStatus,
        errorMessage,
        loading,
    } = useRegisterUser();

    const navigate = useNavigate();

    const roleOptions = [
        {
            label : "Receptionist",
            value : "reception-user"
        }, 
        {
            label : "Contractor",
            value : "contractor"
        }
    ];

  return (
    <div className="login-screen">
        <div className="row">
            <div className="logo-container">
                <img src={Logo} alt="" />
            </div>
            <div className="input-container">
                <h1>Create New Account</h1>
                <form className='login-form'>
                    <SelectInput 
                        label={true} 
                        labelValue="Select Role" 
                        options={roleOptions} 
                        showErrorMessage 
                        onChange={(e) => setRole(e.value)} 
                        selectedOption={role}
                        isError={errorStatus.role}
                        errorMessage={errorMessage.role} 
                    />
                    <LabelInput 
                        value={fullName} 
                        onChange={(e) => setFullName(e.target.value)} 
                        label="Full Name" 
                        placeholder="Enter Full Name"
                        mandatory={true}
                        isError={errorStatus.name}
                        errorMessage={errorMessage.name} 
                    />
                    <LabelInput
                        value={emailAddress} 
                        onChange={(e) => setEmailAddress(e.target.value)}  
                        label="Email Address" 
                        placeholder="Enter Email Address"
                        mandatory={true}
                        isError={errorStatus.email}
                        errorMessage={errorMessage.email}  
                    />
                    <LabelInput
                        value={referenceKey} 
                        onChange={(e) => setRefernceKey(e.target.value)}   
                        label="Reference Key" 
                        placeholder="Enter Reference Key"
                        mandatory={true}
                        isError={errorStatus.refKey}
                        errorMessage={errorMessage.refKey}  
                    />
                    <LabelInput
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}   
                        label="Password" 
                        placeholder="Enter Password"
                        mandatory={true} 
                        type="password"
                        isError={errorStatus.password}
                        errorMessage={errorMessage.password}  
                    />
                    <div className="btn-container">
                        <BasicThemeButton title="Create Account" onClick={onRegisterUserClick} disableStatus={loading} />
                    </div>
                    <p className='reset-password'>Already have an Account ? <span className='link'><a onClick={() => navigate('/')}>Login Now</a></span></p>
                </form>
            </div>
        </div>
        <p className='credits'>TheKeyPower from ViPower</p>
    </div>
  )
}

export default RegisterUserScreen