import React from 'react'
import RightSidebar from 'components/rightSidebar';
import BasicInput from 'components/inputs/basicInput';
import BasicThemeButton from 'components/buttons/basicThemeButton';
import CheckBoxInput from 'components/inputs/checkBoxInput';
import ListSkeleton from 'components/skeleton/ListSkeleton';
import useUserDetails from '../use-user-details';


function AddBuildingAccess() {

    const {
        filterUserApprovedBuildings,
        searchApprovedBuilding, 
        setSearchApprovedBuilding, 
        searchApprovedBuildings,
        closeBuildingAccessModal,
        addBuildingCheckBoxes, 
        handleAddBuildingCheckbox,
        addAccess,
        isBuildingListLoading, 
    } = useUserDetails();

  return (
        <RightSidebar>
            <div className="add-building-access">
                <h2>Add New Access</h2>
                <div className="mt-2">
                    <BasicInput placeholder="Search Building Name" value={searchApprovedBuilding} setValue={searchApprovedBuildings} onChange={(e) => searchApprovedBuildings(e.target.value)} />
                    {!isBuildingListLoading ?
                    <div className="mt-3 scroll">
                        {/* <CheckBoxInput
                            key="_all" 
                            label={
                                <>  
                                    <p>All Buildings</p>
                                </>
                            }
                            id="_all"
                            checked={addBuildingCheckBoxes['_all']}
                            onChange={() => handleAddBuildingCheckbox('_all')}
                        /> */}
                    {   
                        filterUserApprovedBuildings.length > 0 ?
                        filterUserApprovedBuildings.map((building) => {
                            return (
                                <CheckBoxInput
                                    key={building.id} 
                                    label={
                                        <>  
                                            <p>{building.name.name}</p>
                                        </>
                                    }
                                    id={building.id}
                                    checked={addBuildingCheckBoxes[building.id]}
                                    onChange={() => handleAddBuildingCheckbox(building.id)}
                                />
                            )
                        })  : 
                        <>
                            {
                                searchApprovedBuilding == '' ? <p>The user already has access to all buildings currently.</p> : <p>No results to display</p>
                            }
                            
                        </> 
                    }
                    </div> : <ListSkeleton /> }
                </div>
                <div className="row gap-1 footer-button">
                    <BasicThemeButton title="Cancel" color="#E3E3E3" fontColor="#494949" width="100%" onClick={closeBuildingAccessModal} />
                    <BasicThemeButton title="Add Access" width="100%" onClick={addAccess} />
                </div>
            </div>
        </RightSidebar>
  )
}

export default AddBuildingAccess