import React                from 'react'
import { useNavigate }      from 'react-router-dom';
import useResetPassword     from './use-reset-password';
import Logo                 from 'images/The Key Power Logo.png';
import LabelInput           from 'components/inputs/labelInput'
import BasicThemeButton     from 'components/buttons/basicThemeButton';

function ResetPasswordScreen() {

    const navigate = useNavigate();

    const {email, setEmail, loading, errorStatus, errorMessage, onResetBtnClick} = useResetPassword();

  return (
    <div className="login-screen">
        <div className="row">
            <div className="logo-container">
                <img src={Logo} alt="" />
            </div>
            <div className="input-container">
                <h1>Reset Password</h1>
                <form className='login-form'>
                    <LabelInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                        label="Email Address" 
                        placeholder="Enter Email Address"
                        isError={errorStatus.email}
                        errorMessage={errorMessage.email}
                        mandatory={true}
                    />
                    <div className="btn-container">
                        <BasicThemeButton title="Send Reset Email" onClick={onResetBtnClick} disableStatus={loading} />
                    </div>
                    <p className='reset-password'>Already have an Account ? <span className='link'><a onClick={() => navigate('/')}>Login Now</a></span></p>
                </form>
            </div>
        </div>
        <p className='credits'>TheKeyPower from ViPower</p>
    </div>
  )
}

export default ResetPasswordScreen