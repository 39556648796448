import React, {useContext} from 'react'
import SideBar from 'components/sidebar';
import Header from './header';
import SidebarContext from 'contexts/sidebarContext';
import '../sass/main.scss';

function DashboardLayout({children, pageTitle, backHeader}) {

  const {rightSidebarStatus, sidebarStatus} = useContext(SidebarContext);

  return (
    <>
      <div className={`${(rightSidebarStatus || sidebarStatus) ? "overlay" : ""}`} />
        <div className="dashboard-layout">
          {/* Sidebar component will render in all dashboard pages */}
          <SideBar />
          <div className="content-container">
              {/* The content of the page renders here */}
              <div className="container">
                <Header title={pageTitle} backHeader={backHeader} />
                <div style={{ padding: '2rem' }}>
                  {children}
                </div>
              </div>
              
          </div>
      </div>
    </>
  )
}

export default DashboardLayout