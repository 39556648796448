// This context is used to get and set the sidebar open status.
import  { createContext, useState, useEffect, useRef } from 'react';

const SidebarContext = createContext();

export function SidebarProvider({children}) {

    const [sidebarStatus, setSidebarStatus] = useState(false);
    const [rightSidebarStatus, setRightSidebarStatus] = useState(false);
    const sidebarRef = useRef(null);
    const rightSidebarRef = useRef(null);

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);
    
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      },[])
    
      // Close Sidebar if sidebar is open clicked outside of the sidebar.
      function handleClickOutside(event) {
        if (rightSidebarRef.current && !rightSidebarRef.current.contains(event.target)) {
          setRightSidebarStatus(false);
        }

        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setSidebarStatus(false);
        }
      }
      

    return (
        <SidebarContext.Provider value={{sidebarStatus, setSidebarStatus, sidebarRef, rightSidebarStatus, setRightSidebarStatus, rightSidebarRef}}>{children}</SidebarContext.Provider>
    )
}

export default SidebarContext;