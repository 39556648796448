import React    from 'react'
import Select   from 'react-select';

// Select Option Component
// label:           If label should display or not
// labelValue:      Value of Label
// options:         List of options to render in select input
// onChange:        Function to execute when input changes
// selectedOption:  Value of selected option 
// isError:         Error Status of Input Field
// errorMessage:    Message to display on error

function SelectInput({label, labelValue, options, onChange, selectedOption, isError, errorMessage, placeholder, classNames, showErrorMessage = false}) {

  return (
    <div className={classNames ? `select-input-group ${classNames}` : `select-input-group`}>
      {label ? <label>{labelValue}</label> : '' }
        <Select
          classNames={{ control : (state) => isError ? 'error-outline' : '' }}
          value={options.filter(function(option) {
            return option.value === selectedOption;
          })}
          onChange={onChange}
          options={options}
          placeholder={placeholder ? placeholder : 'Select'}
          styles={{ 
            control : (baseStyles, state) => ({
              ...baseStyles,
              fontSize : '14px',
            }),
            option : (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? '#a8e6d0' : 'transparent',
              color: state.isFocused ? '#000' : 'inherit',
              ':hover': {
                backgroundColor: '#a8e6d0',
              },
            })
           }}
      />
      {showErrorMessage ?
      <p className={`error-message ${isError ? 'active' : ''}`}>{errorMessage ? errorMessage : 'Error Message'}</p>
      : '' }
    </div>
  )
}

export default SelectInput

// Stylesheet: sass/components/inputs/_input.scss