import React, { useState, useEffect, useContext } from 'react';
import { CSVLink }                                from "react-csv";
import { capitalizeWords, checkIfEmpty }                        from 'functions/functions';
import { ToastContainer, toast }                  from 'react-toastify';
import { getAllOutStandingKeys, addNote }                  from '../services/keyServices';
import moment from 'moment';
import Modal                                      from 'react-modal';
import DataTable                                  from "react-data-table-component";
import DataTableSkeleton                          from 'components/skeleton/dataTableSkeleton';
import DashboardLayout                            from '../layouts/dashboardLayout';
import UserContext                                from '../contexts/userContext';
import BasicInput                                 from 'components/inputs/basicInput';
import BasicThemeButton                           from 'components/buttons/basicThemeButton';
import location_icon                              from '../images/location-pin.png';
import { showSuccessToast, showErrorToast } from 'utils/toasts';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width : '35%',
    transform: 'translate(-50%, -50%)',
    minHeight : '30%'
  },
};


function OutstandingKeysScreen() {

  const [data, setData]                 = useState([]);
  const [modalData, setModalData]       = useState([]);
  const [searchValue, setSearchValue]   = useState('');
  const [noteValue, setNoteValue]       = useState('');
  const [modalIsOpen, setModalIsOpen]   = useState(false);
  const [loading, setLoading]           = useState(true);

  const {user} = useContext(UserContext);

  const header = [
    {label : "Visitor Name",  key : "visitorName"},
    {label : "Company",       key : "visitorCompany"},
    {label : "Email",         key : "visitorEmail"},
    {label : "Phone No.",     key : "visitorPhone"},
    {label : "Tag Name",      key : "tag"},
    {label : "Key No.",       key : "keyNumber"},
    {label : "Issue Time",    key : "issueTime"},
  ];

  const columns = [
    {name: 'Visitor Name', selector: row => capitalizeWords(row.visitorName),},
    {name : "Company", selector : row => capitalizeWords(row.visitorCompany)},
    {name : "Tag Name", selector : row => capitalizeWords(row.tag)},
    {name : "Key No.", selector : row => row.keyNumber},
    {name : "Email", selector : row => row.visitorEmail},
    {name : "Phone No.", selector : row => row.visitorPhone},
    {name : "Issue Time", selector : row => row.issueTime},
    {name : "Location", button : true, cell : (row) => (
      <img className='location-pin' src={location_icon} onClick={()=> onLocationClick(row.location)} />
    )}
  ];

  const onLocationClick = (location) => {
    if(location[0] && location[1]) window.open(`https://maps.google.com?q=${location[0]},${location[1]}`, '_blank', 'noreferrer');
    else toast.error("No Location Details");
  }

  useEffect(() => {
    setLoading(true);
    getAllOutStandingKeys(user.buildingId).then((res) => {
      setData(res.result);
      setTimeout(() => {
        setLoading(false);
      }, 500)
    })
  }, [])

  const fetchData = async () => {
    getAllOutStandingKeys(user.buildingId).then((res) => {
      setData(res.result);
      setTimeout(() => {
        setLoading(false);
      }, 500)
    })
  }

  const onSearchValueChanges = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  }

  const rowClick = (e) => {
    setModalData(e);
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const addNoteValue = async (e) => {
    e.preventDefault();
    let errorStatusObj = {};
    let errorMessageObj = {};

    if(checkIfEmpty(noteValue)) {
      errorStatusObj.note = true;
      errorMessageObj.note = 'Please enter note'
    }

    if(!errorStatusObj.note) {
      const response = await addNote(modalData.id, noteValue, modalData.notes);
      if(response.status) {
        await fetchData();
        showSuccessToast('Note Added');
        setNoteValue('');
      }
    }
  }
  
  return (
      <DashboardLayout pageTitle="Outstanding Keys">
          <div className="row gap-1">
            <BasicInput  placeholder="Search by Visitor Name or Key Tag" value={searchValue} setValue={setSearchValue} onChange={onSearchValueChanges} />
            <div className="export-data">
              <CSVLink data={data} headers={header} filename="Outstanding_keys.csv">
                <BasicThemeButton title="Export to CSV" />
              </CSVLink>
            </div>
        </div>
          <div className="table-container">
            {loading ?
            <DataTableSkeleton /> :
            <DataTable 
              columns={columns}
              onRowClicked={rowClick}
              data={data.filter(item => {
                if(searchValue == '') return item
                if(item.visitorName.includes(searchValue) || item.tag.includes(searchValue)) return item
              })}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
              paginationComponentOptions={{
                rowsPerPageText: "Records per page:",
                rangeSeparatorText: "out of"
              }}
            /> }
          </div>


          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
              <div className="modal-content outstanding-keys-modal">
                  <h2>{modalData.tag} - {modalData.keyNumber}</h2>
                  <div className='row'>
                    <div className='key-issue-details'>
                      <p><span className='bold'>Visitor Name : </span>{modalData.visitorName}</p>
                      <p><span className='bold'>Company : </span>{modalData.visitorCompany}</p>
                      <p><span className='bold'>Email : </span>{modalData.visitorEmail}</p>
                      <p><span className='bold'>Phone No. : </span>{modalData.visitorPhone}</p>
                      <p><span className='bold'>Issue Time. : </span>{modalData.issueTime}</p>
                      <p><span className='bold'>Location : </span><a className='link' onClick={() => onLocationClick(modalData.location)}>See Location</a></p>
                    </div>
                    <div className='notes'>
                      <form>
                      <div className='row'>
                        <div className='left'>
                          <BasicInput value={noteValue} onChange={(e) => setNoteValue(e.target.value)} placeholder="Enter Notes (if any)" />
                        </div>
                        <div className='right'>
                          <BasicThemeButton title="Add Note" onClick={addNoteValue} />
                        </div>
                      </div>
                      </form>
                      <div className='notes-list'>
                        {modalData.notes?.length > 0 ? modalData.notes.slice().sort(((a,b) => b.timestamp - a.timestamp)).map((note, index) => {
                          return (
                            <div className='note' key={index}>
                              <p>{note.note}</p>
                              <p className='timestamp'>{note.userName ? `${capitalizeWords(note.userName)} - `: ''} {moment(note?.timestamp.toDate()).format('MM/DD/YYYY hh:mm a')}</p>
                            </div>
                          )
                        }) : <p>No Notes Added</p>}
                        
                      </div>
                    </div>
                  </div>
              </div>
          </Modal>
      </DashboardLayout>
  )
}

export default OutstandingKeysScreen