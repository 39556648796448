import React from 'react'

// Card Layout
// children => Content to be added inside the card layout

function Card({children, classNames, disabled=false, disabledMessage}) {
  return (
    <>
      <div className={classNames ? `card ${classNames}` :  `card`} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
          {disabled ? <div className='card-disabled-overlay'>
            <p>{disabledMessage}</p>
          </div> : ''}
          {children}
      </div>
    </>
  )
}

export default Card

// Stylesheet: sass/layouts/_card.scss