import React, { useContext }                      from 'react'
import { CSVLink }                                from "react-csv";
import Modal                                      from 'react-modal';
import DataTableSkeleton                          from 'components/skeleton/dataTableSkeleton';
import ModalInputSkeleton                         from 'components/skeleton/ModalInputSkeleton';
import DashboardLayout                            from 'layouts/dashboardLayout'
import UserContext                                from 'contexts/userContext'
import DataTable                                  from "react-data-table-component";
import BasicThemeButton                           from 'components/buttons/basicThemeButton';
import BasicInput                                 from 'components/inputs/basicInput';
import LabelInput                                 from 'components/inputs/labelInput';
import SelectInput                                from 'components/inputs/selectInput';
import useManageUser                              from './use-manage-users';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width : '35%',
    transform: 'translate(-50%, -50%)',
    minHeight : '30%'
  },
};

function ManageUsersScreen() {


  const {
        data,
        filteredData, 
        searchValue,
        setSearchValue,
        selectedRoleFilter,
        onRoleFilterSelected,
        modalIsOpen, 
        closeModal,
        invitedUsers,
        invitedUsersData,
        invitedUsersModalStatus,
        invitedUsersModalLoading, 
        openInvitedUsersModal,
        closeInvitedUsersModal,
        pendingUsers,
        roleOptions,
        header,
        columns,
        rowClick,
        addNewUserModal,
        managerBuildings, 
        addUserRoleOptions,
        onBuildingSelect,
        onRoleSelect,
        addUserSelectedBuilding, 
        addUserSelectedRole,
        addUserName,
        setAddUserName, 
        addUserEmail, 
        setAddUserEmail,
        addUserPassword,
        setAddUserPassword,
        onAddUserSubmit,
        errorStatus,
        errorMessage,
        loading,
        modalLoading, 
  } = useManageUser();

  const {user} = useContext(UserContext);
  
  return (
      <DashboardLayout pageTitle="Manage Users">
        <div className="manage-users">
          <div className="row gap-1">
            <div className="options-left">
              <BasicInput  placeholder="Search by Name/Email Address" value={searchValue} setValue={setSearchValue} onChange={(e)=> setSearchValue(e.target.value)} />
              <SelectInput label={false} placeholder="Select Role" options={roleOptions} selectedOption={selectedRoleFilter} onChange={onRoleFilterSelected} />
            </div>
            <div className="options-right">
              <CSVLink data={data} headers={header} filename="Users_List.csv">
                <BasicThemeButton title="Export to CSV" />
              </CSVLink>
              <BasicThemeButton title="+ Add New User" color="#002865" onClick={addNewUserModal} />
            </div>
          </div>
          <div className='invited-users-container' onClick={openInvitedUsersModal}>
            <p>View Invited Users</p>
          </div>
          <div className="table-container" style={{ marginTop : '1%' }}>
            {loading ? 
            <DataTableSkeleton /> : 
            <DataTable 
              columns={columns}
              onRowClicked={rowClick}
              data={filteredData.filter(item => {
                if(searchValue == '') return item
                if(item.fullName.toLowerCase().includes(searchValue.toLowerCase()) || item.email.toLowerCase().includes(searchValue.toLowerCase())) return item
              })}
              highlightOnHover
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
              paginationComponentOptions={{
                rowsPerPageText: "Records per page:",
                rangeSeparatorText: "out of"
              }}
            /> }
          </div>
          
          {/* Add New User Modal */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
              <div className="add-new-modal modal-content">
                  <h2>Add New User</h2>
                  {!modalLoading ?
                  <>
                    <div className="row gap-1">
                      <SelectInput 
                        isError={errorStatus.building} 
                        errorMessage={errorMessage.building} 
                        label={true} 
                        labelValue="Building" 
                        placeholder="Select Building" 
                        selectedOption={addUserSelectedBuilding} 
                        options={managerBuildings} 
                        onChange={onBuildingSelect}
                        showErrorMessage={true} 
                        classNames="mt-1" 
                        />
                      <SelectInput 
                        isError={errorStatus.role} 
                        errorMessage={errorMessage.role} 
                        label={true} 
                        labelValue="Role" 
                        placeholder="Select Role" 
                        selectedOption={addUserSelectedRole} 
                        options={addUserRoleOptions} 
                        onChange={onRoleSelect} 
                        showErrorMessage={true} 
                        classNames="mt-1" 
                        />
                    </div>
                    {addUserSelectedRole == 'guest-user' ?
                    <>
                      <LabelInput 
                        isError={errorStatus.name} 
                        errorMessage={errorMessage.name}
                        label="Name" 
                        placeholder="Enter Name" 
                        value={addUserName} 
                        onChange={(e) => setAddUserName(e.target.value)} 
                        classNames="mt-1" 
                        />
                        {/* <p>Generate Password</p> */}
                      </> 
                      : '' }
                      <LabelInput 
                        isError={errorStatus.email} 
                        errorMessage={errorMessage.email} 
                        label="Email Address" 
                        placeholder="Enter Email Address" 
                        value={addUserEmail} 
                        onChange={(e) => setAddUserEmail(e.target.value)}
                        classNames="mt-1" 
                      />
                    {addUserSelectedRole == 'guest-user' ?
                    <>
                      <LabelInput 
                        isError={errorStatus.password} 
                        errorMessage={errorMessage.password}
                        label="Password" 
                        placeholder="Enter Password" 
                        value={addUserPassword} 
                        onChange={(e) => setAddUserPassword(e.target.value)} 
                        classNames="mt-1" 
                        />
                        {/* <p>Generate Password</p> */}
                      </> 
                      : '' }
                    <div className="row mt-3 justify-end">
                      <BasicThemeButton title="Send Invitation" onClick={onAddUserSubmit} />
                    </div>
                  </> : <ModalInputSkeleton /> }
              </div>
          </Modal>


          {/* Invited Users Modal */}
          <Modal
            isOpen={invitedUsersModalStatus}
            onRequestClose={closeInvitedUsersModal}
            style={customStyles}
            contentLabel="Invited Users Modal">
              <div className="add-new-modal modal-content">
                  <h2>Invited Reception Users</h2>
                  {invitedUsersModalLoading ? 
                  <DataTableSkeleton height="200" /> : 
                  <DataTable
                      columns={invitedUsers}
                      data={pendingUsers}
		                  dense
                      pagination
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 15, 20]}
                      paginationComponentOptions={{
                          rowsPerPageText: "Records per page:",
                          rangeSeparatorText: "out of"
                      }}
                  /> }
              </div>
          </Modal>

          </div>

          
      </DashboardLayout>
      
  )
}

export default ManageUsersScreen