import {auth, db } from '../firebaseConfig';
import { doc, getDoc } from "firebase/firestore";


export const getBuildingNameById = async (buildingId) => {
    const docRef = doc(db, "buildings", buildingId);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {
        const data = docSnap.data();
        return {'status' : true ,'name' : data.name};
    } else {
        return {'status' : false ,'message' : 'Building Not Exists'};
    }
}