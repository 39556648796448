import React, { useState, useContext }  from 'react';
import { NavLink, useNavigate }         from 'react-router-dom';
import SidebarContext                   from 'contexts/sidebarContext';

// Single Menu Item Component
function MenuItem(props) {
    const { name, to, exact, subMenus, iconClassName } = props;
    const {sidebarStatus, setSidebarStatus} = useContext(SidebarContext);
    const [exapnd, setExpand] = useState(false);
    const navigate = useNavigate();

    // Function when a menu is clicked
    const onNavClick = (e, to) => {
        e.preventDefault();
        setExpand(!exapnd);
        if(sidebarStatus) {
            setSidebarStatus(false);
        }
        navigate(to);
    }

  return (
    <li>
        {to ? (
            <NavLink to={to} onClick={(e)=> onNavClick(e, to)} className='menu-item'>
                <div className="menu-icon">
                    <i className={iconClassName}></i>    
                </div>    
                <span>{name}</span>
            </NavLink>
        ) : 
        (
            <a onClick={()=> setExpand(!exapnd)} className={`menu-item ${exapnd ? "expand-active" : ""}`}>
                <div className="menu-icon">
                    <i className={iconClassName}></i>    
                </div>    
                <span>{name}</span>
            </a> 
        )}
        
        { subMenus && subMenus.length > 0 ? (
        <ul className={`sub-menu ${exapnd ? "active" : ""}`}>
            {subMenus.map((menu, index) => (
                <li key={index}>
                    <NavLink to={menu.to}>{menu.name}</NavLink>
                </li>
            ))}
        </ul> ) : null }
    </li>
  )
}

export default MenuItem

// Stylesheet: sass/components/sidebar/_sidebar.scss