import DashboardLayout from "layouts/dashboardLayout";
import BasicInput from "components/inputs/basicInput";
import BasicThemeButton from "components/buttons/basicThemeButton";
import DataTable from "react-data-table-component";
import UserModal from "./userModal";
import DataTableSkeleton from "components/skeleton/dataTableSkeleton";
import { CSVLink } from "react-csv";
import useContractor from "./useContractor";



const ContractorDashboard = () => {

  const {
    columns,
    modalOpen, closeModal,
    openAddUserModal,
    onRowClicked,
    buildings, setBuildings,
    data, setData,
    filteredData, setFilteredData,
    searchValue, setSearchValue,
    selectedUser,
    modalMode,
    fetchUserData
  } = useContractor();

  return (
    <>
    <DashboardLayout pageTitle="Dashboard">
        <div className="contractor-portal">
            <div className="row gap-1 top-header">
              <div className="left-options">
                <BasicInput placeholder="Search by name" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
              </div>
              <div className="right-options">
                <BasicThemeButton title="+ Add New User" onClick={openAddUserModal} />
                {/* <BasicThemeButton title="Export to CSV" /> */}
              </div>
            </div>
            <div className="content">
              <div className="table-container" style={{ marginTop : '1%' }}>
                {filteredData.length > 0 ?
                <DataTable 
                  columns={columns}
                  data={filteredData.filter(item => {
                    if(searchValue == '') return item
                    if(item.fullName.toLowerCase().includes(searchValue.toLowerCase())) return item
                  })}
                  onRowClicked={onRowClicked}
                  highlightOnHover
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Records per page:",
                    rangeSeparatorText: "out of"
                  }}
                /> : <p>You haven't added any users. Click on Add New User button to register new user to TheKeyPower application.</p> }
              </div>
            </div>
        </div>
    </DashboardLayout>
    {modalOpen ? <UserModal 
      modalOpen={modalOpen} 
      closeModal={closeModal}
      fetchUserData={fetchUserData}
      contractorApprovedBuildings = {buildings}
      selectedUser={selectedUser}
      modalMode={modalMode} 
    /> : '' }
    </>
  )
}

export default ContractorDashboard