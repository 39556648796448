import React, {useContext, useState, useEffect} from 'react'
import UserContext from '../contexts/userContext'
import { BiMenuAltLeft, BiArrowBack } from "react-icons/bi";
import profile_img from '../images/user.png';
import BuildingIcon from 'images/building.png';
import Modal from 'react-modal';
import { getBuildingNameById } from '../services/buildingServices';
import SidebarContext from 'contexts/sidebarContext';
import HeaderContext from 'contexts/headerContext';
import BasicInput from 'components/inputs/basicInput';
import ListSkeleton from 'components/skeleton/ListSkeleton';
import { useNavigate } from 'react-router-dom';
import { capitalizeWords } from 'functions/functions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minHeight : '35%',
    marginRight: '-50%',
    width : '35%',
    transform: 'translate(-50%, -50%)',
    maxHeight : "500px"
  },
};

Modal.setAppElement('#root');

function Header({title, backHeader}) {

  const {buildingOptionStatus, setBuildingOptionStatus, profileOptionStatus, setProfileOptionStatus, buildingOptionRef, profileOptionRef} = useContext(HeaderContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const {user, saveUserDetails} = useContext(UserContext);
  const {sidebarStatus, setSidebarStatus} = useContext(SidebarContext);
  // const [buildingName, setBuildingName] = useState(user.buildingName);
  const [buildingList, setBuildingList] = useState([]);
  const [filterBuildingList, setFilterBuildingList] = useState([]);
  const [buildingSearchValue, setBuildingSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
    // getBuildingNames(user.approvedBuildings).then((res) => {
    //   setBuildingList(res);
    //   setFilterBuildingList(res);
    //   setLoading(false);
    // })

    const response = await getBuildingNames(user.approvedBuildings);
    setBuildingList(response);
    setFilterBuildingList(response);
    setTimeout(() => {
      setLoading(false);
    }, 500)
    
    }
    
    fetchData();
    
  },[])

  const getBuildingNames = async (buildingArray) => {
    const buildingPromises = buildingArray.map(async (buildingId) => {
        const res = await getBuildingNameById(buildingId);
        return {
          name: res.name,
          value: buildingId
        };
    });

    const buildingNameArray = await Promise.all(buildingPromises);

    return buildingNameArray;
}

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onBuildingChange = (buildingId) => {
    const userData = user;
    getBuildingNameById(buildingId).then((res) => {
      userData.buildingId = buildingId;
      userData.buildingName = res.name;
      saveUserDetails(userData);
      window.location.reload(false);
    })
  }

  const searchByBuildingName = (buildingName) => {
    if(buildingName == '') {
      setFilterBuildingList(buildingList);
    } else {
      const filteredData = filterBuildingList.filter(building => {
        if(building.name.toLowerCase().includes(buildingName.toLowerCase())) return building
      })
      setFilterBuildingList(filteredData);
    }
  }

  return (
    <div className="header">
        <div className='header__left'>
          <BiMenuAltLeft className='mobile-menu' onClick={()=> setSidebarStatus(!sidebarStatus)} size={30} />
          {backHeader ? <BiArrowBack onClick={()=> navigate(-1)} size={25} /> : ''}
          <h2>{title}</h2>
        </div>
        <div className='header__right'>
          {user.userType !== 'contractor' ?
          <div className="building-container">
            <img src={BuildingIcon} onClick={() => setBuildingOptionStatus(true)} />
            <div className='building-container__right'>
              <h3 onClick={() => setIsOpen(true)}>{user.buildingName}</h3>
              <p onClick={() => setIsOpen(true)}>Change Building</p>
            </div>
            <div className={`building-option-mobile ${buildingOptionStatus ? 'active' : ''}`} ref={buildingOptionRef}>
                <h3>{user.buildingName}</h3>
                <p onClick={() => { setIsOpen(true); setBuildingOptionStatus(false) }}>Change Building</p>
            </div>
          </div> : '' }
          <div className="user-container">
              <div className="profile-pic-container" onClick={() => {setProfileOptionStatus(!profileOptionStatus)}}>
                  <img src={profile_img} alt="Profile Pic" />
              </div>
              <div className="user-details-container">
                  <h4>{user.userName ? capitalizeWords(user.userName) : ''}</h4>
                  <p>{user.email}</p>
              </div>
              <div className={`profile-option-mobile ${profileOptionStatus ? 'active' : ''}`} ref={profileOptionRef}>
                <h3>{user.userName ? capitalizeWords(user.userName) : ''}</h3>
                <p>{user.email}</p>
              </div>
          </div>
        </div>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
          <div className="modal-content building-select">
              <h2>Select the Building</h2>
              <BasicInput placeholder="Search Building Name" value={buildingSearchValue} onChange={(e) => searchByBuildingName(e.target.value)} />
              {!loading ?
              <div className='building-select__list'>
                <ul>
                  {filterBuildingList.filter(building => building.value != '').map((building) => {
                    return (
                        <li key={building.value} onClick={() => onBuildingChange(building.value)}>{building.name}</li>
                    )
                  })}
                </ul>
              </div> : <ListSkeleton /> }
          </div>
        </Modal>
    </div>
  )
}

export default Header