import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAuth } from "firebase/auth";

// Firebase Configuration Values
const firebaseConfig = {
    apiKey: "AIzaSyCBlljp2aplabqV6_NIcunlvsMkRcjPDVU",
    authDomain: "thekeypower-flutter.firebaseapp.com",
    projectId: "thekeypower-flutter",
    storageBucket: "thekeypower-flutter.appspot.com",
    messagingSenderId: "26527281449",
    appId: "1:26527281449:web:4d3a4e0c41255e88784738"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, auth, db, firebaseConfig}