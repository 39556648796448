import React, {useContext, useEffect, useState }    from 'react'
import { CSVLink }                                  from "react-csv";
import { capitalizeWords }                          from 'functions/functions';
import { getAllKeysByBuildingId, getAllKeysData }                   from '../services/keyServices';
import UserContext                                  from '../contexts/userContext'
import DataTable                                    from "react-data-table-component";
import DataTableSkeleton                            from 'components/skeleton/dataTableSkeleton';
import DashboardLayout                              from '../layouts/dashboardLayout'
import BasicInput                                   from 'components/inputs/basicInput';
import SelectInput                                  from 'components/inputs/selectInput';
import BasicThemeButton                             from 'components/buttons/basicThemeButton';

// Key Registry Screen Component
function KeyRegistryScreen() {

    const [data, setData]                       = useState([]);
    const [filteredData, setFilteredData]       = useState([]);
    const [searchValue, setSearchValue]         = useState('');
    const [keyStatus, setKeyStatus]             = useState('all');
    const [keyColorValue, setKeyColorValue]     = useState('all');
    const [loading, setLoading]                 = useState(true);

    const {user} = useContext(UserContext);

    // Headers for the CSV Files
    const header = [
        {label : "Tag Name",    key : "tag"},
        {label : "Key No.",     key : "number"},
        {label : "Colour",      key : "color"},
        {label : "Status",      key : "status"}
    ];

    // Columns for the Table
    const columns = [
        {name: 'Tag Name', selector: row => capitalizeWords(row.tag)},
        {name: 'Key No.', selector: row => row.number},
        {
            name : "Colour", 
            cell : (row) => (
                <div style={{ 'display': 'flex', 'gap' : '0.7rem', 'alignItems' : 'center'}}>
                    <div className={`color-preview ${row.color.toLocaleLowerCase()}`}></div>
                    <p>{capitalizeWords(row.color)}</p>
                </div>
            )
        },
        {
            name : "Status", 
            selector : row => row.status,
            cell : (row) => (
                <p className={`badge ${row.status == 'Free' ? 'active-badge' : 'in-active-badge'}`}>{row.status}</p>
            )
        },
    ];

    useEffect(() => {
        fetchData();
    }, [])


    const fetchData = async () => {
        setLoading(true);
        const response = await getAllKeysData(user.buildingId);
        setData(response.result);
        setFilteredData(response.result);

        setLoading(false)
    }

    // When Key Status Changes
    const onKeyStatusChange = (e) => {
        const selectedOption = e.value;
        setKeyStatus(selectedOption);
        let filteredResult;
        if(selectedOption != 'all') {
            filteredResult = data.filter((data) => {
                return data.status == selectedOption;
            })
        } else {
            filteredResult = data
        }

        if(keyColorValue != 'all') {
            filteredResult = filteredResult.filter((data) => {
                return data.color.toLowerCase() == keyColorValue;
            })
        }

        setFilteredData(filteredResult);
    }

    // When Key Color Changes
    const onKeyColorChange = (e) => {
        const selectedOption = e.value;
        setKeyColorValue(selectedOption);
        let filteredResult;
        if(selectedOption != 'all') {
            filteredResult = data.filter((data) => {
                return data.color.toLowerCase() == selectedOption;
            })
            
        } else {
            filteredResult = data
        }

        if(keyStatus != 'all') {
            filteredResult = filteredResult.filter((data) => {
                return data.status == keyStatus;
            })
        }

        setFilteredData(filteredResult);
    }
    
    const keyOptions = [{label:"All Keys", value: "all"}, {label:"Free Keys", value: 'Free'}, {label:"In Use Keys", value: 'In-Use'}];
    const colorOptions = [{label:"All Colours", value: "all"}, {label:"Red", value: "red"}, {label:"Blue", value: "blue"}, {label:"Purple", value: "purple"}, {label:"Green", value : "green"}, {label : "Yellow", value : "yellow"}];
    
    return (
        <DashboardLayout pageTitle="Key Registry">
            <div className="row gap-1">
                <BasicInput placeholder="Search by Tag Name" value={searchValue} setValue={setSearchValue} onChange={(e) => setSearchValue(e.target.value)}  />
                <SelectInput label={false} placeholder="Select Key Status" options={keyOptions} selectedOption={keyStatus} onChange={onKeyStatusChange} />
                <SelectInput label={false} placeholder="Select Key Colour" options={colorOptions} selectedOption={keyColorValue} onChange={onKeyColorChange} />
                <div className="export-data">
                    <CSVLink data={data} headers={header} filename="Key_Registry.csv">
                        <BasicThemeButton title="Export to CSV" />
                    </CSVLink>
                </div>
            </div>
            <div className="table-container">
                {loading ?
                    <DataTableSkeleton /> :
                    <DataTable 
                        columns={columns}
                        data={filteredData.filter(item => {
                            if(searchValue == '') return item
                            if(item.tag.toLowerCase().includes(searchValue.toLocaleLowerCase())) return item
                        })}
                        highlightOnHover
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                        paginationComponentOptions={{
                            rowsPerPageText: "Records per page:",
                            rangeSeparatorText: "out of"
                        }}
                /> }
            </div>
        </DashboardLayout>
    )
}

export default KeyRegistryScreen