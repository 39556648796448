import React from 'react'
import '../../sass/main.scss'

function ThemeButton({status, title, onPress, flexValue}) {
  return (
    <button 
      style={{ 
        cursor : !status ? 'not-allowed' : 'pointer', 
        backgroundColor : !status ? '#bdbdbd' : '#2a8d6a'
      }} 
      className = {flexValue ? `${flexValue} theme-button` : "theme-button"} 
      onClick={status ? onPress : ()=> {}}>
        {title}
    </button>
  )
}

export default ThemeButton