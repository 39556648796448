import { useContext, useEffect, useState } from "react";
import UserContext from "contexts/userContext";
import { getBuildingNameById } from "services/buildingServices";
import UserService from "services/userServices";

const useContractor = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [modalMode, setModalMode] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const {user} = useContext(UserContext);
    const { getContractorGuestUser } = UserService();

    useEffect(() => {
        fetchBuildings();
    }, [user]);

    useEffect(() => {
        fetchUserData();
    },[]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.fullName,
        },
        {
            name: 'Email Address',
            selector: row => row.email,
        },
        {
            name: 'Status',
            selector: row => row.approvalFlag ? 'Active' : 'Not Active',
        },
    ];
    
    // const data = [
    //       {
    //         id: 1,
    //         title: 'Beetlejuice',
    //         year: '1988',
    //     },
    //     {
    //         id: 2,
    //         title: 'Ghostbusters',
    //         year: '1984',
    //     },
    // ]

    // Get names of approved buildings
    const fetchBuildings = async () => {

        const approvedBuildingList = await Promise.all(
  
            user.approvedBuildings[user.manager].map(async(building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName.name,
                    'status' : buildingName.status,
                    'id' : building
                };
            })
        );
        setBuildings(approvedBuildingList);
    }

    // Get list of users that the contractor added
    const fetchUserData = async () => {
        const result = await getContractorGuestUser(user.userId);

        setData(result);
        setFilteredData(result);
    }

    // Open Add User Modal
    const openAddUserModal = () => {
        setModalMode('add');
        setModalOpen(true);
    }

    // Function to close Modal
    const closeModal = () => {
        setModalMode('');
        setSelectedUser('')
        setModalOpen(false);
    }

    // When user row is clicked
    const onRowClicked = (user) => {
        setSelectedUser(user);
        setModalMode('edit');
        setModalOpen(true)
    }

    return {
        columns, data,
        modalOpen, closeModal,
        openAddUserModal,
        onRowClicked,
        buildings, setBuildings,
        data, setData,
        filteredData, setFilteredData,
        searchValue, setSearchValue,
        selectedUser,
        modalMode,
        fetchUserData,
    }
}

export default useContractor