import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, redirect, Navigate } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import { PAGE_ROOT } from './constants';
import { SidebarProvider } from 'contexts/sidebarContext';
import { HeaderProvider } from 'contexts/headerContext';
import { ToastContainer }             from 'react-toastify';
import LoginScreen from './pages/login';
import KeyRegistryScreen from './pages/keyRegistry';
import OutstandingKeysScreen from './pages/outstandingKeys';
import KeyLoanHistoryScreen from './pages/keyLoanHistory';
import ManageUsersScreen from 'pages/manageUsers/manageUsers';
import UserDetasilsScreen from 'pages/manageUsers/user-details/user-details';
import RegisterUserScreen from 'pages/registerUser/register-user';
import ResetPasswordScreen from 'pages/resetPassword/reset-password.jsx';
import Dashboard from 'pages/dashboard';
import AuthRoute from './authRoute';
import RedirectRoute from './redirectRoute';
import ManagerRoute from 'managerRoute';

import 'react-toastify/dist/ReactToastify.css';
import ContractorRoute from 'contractorRoute';
import ContractorDashboard from 'pages/contractor/contractor';
import PortalRoute from 'portalRoute';


function App() {
  return (
    <div className="App" style={{height: '100%'}}>
      <UserProvider>
        <BrowserRouter basename={PAGE_ROOT}>
          <Routes>
            <Route path = "/" element={
              <RedirectRoute>
                <LoginScreen />
              </RedirectRoute>
              } />
              <Route path = "/register" element={
                <RegisterUserScreen />
              } />
              <Route path = "/reset-password" element={
                <ResetPasswordScreen />
              } />
          </Routes>
          <SidebarProvider>
            <HeaderProvider>
            <Routes>
              <Route path = "/key-registry" element={
                <AuthRoute>
                  <PortalRoute>
                    <KeyRegistryScreen />
                  </PortalRoute>
                </AuthRoute>
              } />
            </Routes>
            <Routes>
              <Route path = "/outstanding-keys" element={
                <AuthRoute>
                  <PortalRoute>
                    <OutstandingKeysScreen />
                  </PortalRoute>
                </AuthRoute>
              } />
            </Routes>
            <Routes>
              <Route path = "/key-loan-history" element={
              <AuthRoute>
                <PortalRoute>
                  <KeyLoanHistoryScreen />
                </PortalRoute>
              </AuthRoute>
              }  />
            </Routes>
            <Routes>
              <Route path = "/manage-users" element={
              <AuthRoute>
                <ManagerRoute>
                  <ManageUsersScreen />
                </ManagerRoute>
              </AuthRoute>
              }  />
            </Routes>
            <Routes>
              <Route path = "/manage-users/:id" element={
              <AuthRoute>
                <ManagerRoute>
                  <UserDetasilsScreen />
                </ManagerRoute>
              </AuthRoute>
              }  />
            </Routes>
            <Routes>
              <Route path = "/dashboard" element={
              <AuthRoute>
                  <PortalRoute>
                    <Dashboard />
                  </PortalRoute>
              </AuthRoute>
              }  />
            </Routes>
            <Routes>
              <Route path = "/contractor-dashboard" element={
              <AuthRoute>
                <ContractorRoute>
                  <ContractorDashboard />
                </ContractorRoute>
              </AuthRoute>
              }  />
            </Routes>
            </HeaderProvider>
          </SidebarProvider>
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ fontSize: '14px' }}
          />
        </BrowserRouter>
      </UserProvider>
    </div>
  );
}

export default App;
