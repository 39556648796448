import { useContext } from 'react';
import { db } from '../firebaseConfig';
import moment from 'moment';
import UserContext from 'contexts/userContext';
import { collection, query, where, doc, updateDoc, getDocs, orderBy, Timestamp} from "firebase/firestore";


// FuFunction to get first set of key registry data based on search parameters
export const getAllKeysByBuildingId = async(buildingId, keyStatus, keyColorValue) => {
    const docRef = collection(db, "allKeys");
    let keyQuery = query(docRef, orderBy('tag', 'asc'), 
        where("buildingID", "==", buildingId),
        where("status", "in", keyStatus));
    if(keyColorValue != '') {
        keyQuery = query(keyQuery, where("colour", "==", keyColorValue));
    }
    const querySnapshot = await getDocs(keyQuery);
    let resultArray = [];
    querySnapshot.forEach((doc) => {
        const key = doc.data();
        resultArray.push({
            number : key.number,
            tag : key.tag,
            status : key.status ? 'In-Use' : 'Free',
            color : key.colour
        })
    });
    return {result : resultArray};  
}

// FuFunction to get first set of key registry data based on search parameters
export const getAllKeysData = async(buildingId) => {
    const docRef = collection(db, "allKeys");
    let keyQuery = query(docRef, orderBy('tag', 'asc'), 
        where("buildingID", "==", buildingId));
        
    const querySnapshot = await getDocs(keyQuery);
    let resultArray = [];
    querySnapshot.forEach((doc) => {
        const key = doc.data();
        resultArray.push({
            number : key.number,
            tag : key.tag,
            status : key.status ? 'In-Use' : 'Free',
            color : key.colour
        })
    });
    return {result : resultArray};  
}


// Function to get first set of outstanding keys data based on search parameters
export const getAllOutStandingKeys = async (buildingId) => {
    const docRef = collection(db, "records");
    let keyQuery = query(docRef,
        where("buildingID", "==", buildingId),
        orderBy('issueTime', 'desc'));
    const querySnapshot = await getDocs(keyQuery);
    let resultArray = [];
    querySnapshot.forEach((doc) => {
        const key = doc.data();
        const id = doc.id;
        resultArray.push({
            issueTime : moment(key.issueTime.toDate()).format('MM/DD/YYYY hh:mm a'),
            visitorCompany : key.visitorCompany,
            visitorEmail : key.visitorEmail,
            visitorName : key.visitorName,
            visitorPhone : key.visitorPhoneNo,
            keyNumber : key.keyNumber,
            tag : key.keyTag,
            status : key.status,
            location : key.location,
            notes : key.notes ? key.notes : [],
            id: id
        })
    });
    return {result : resultArray};
}

export const addNote = async (id, noteValue, currentNotes) => {
    const userFromStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : '';
    try {

        const keyDocRef = doc(db, "records", id);
        currentNotes.push({
            'note' : noteValue,
            'timestamp' : Timestamp.fromDate(new Date()),
            'userId' : userFromStorage.userId,
            'userName' : userFromStorage.userName
        }); 

        await updateDoc(keyDocRef, {
            notes : currentNotes
        });

        return {
            status : true,
            message : "Updated Successfully"
        }

    } catch (err) {
        return {
            status : false,
            message : err
        }
    }
}

// Function to get first set of outstanding keys data based on search parameters
export const getAllKeyLoanData = async (buildingId) => {
    const docRef = collection(db, "returnedKeys");
    let keyQuery = query(docRef,
        where("buildingID", "==", buildingId),
        orderBy('issueTime', 'desc'));
    const querySnapshot = await getDocs(keyQuery);
    let resultArray = [];
    querySnapshot.forEach((doc) => {
        const key = doc.data();
        resultArray.push({
            issueTime : moment(key.issueTime.toDate()).format('MM/DD/YYYY hh:mm a'),
            returnTime : moment(key.returnTime.toDate()).format('MM/DD/YYYY hh:mm a'),
            visitorCompany : key.visitorCompany,
            visitorEmail : key.visitorEmail,
            visitorName : key.visitorName,
            visitorPhone : key.visitorPhoneNo,
            keyNumber : key.keyNumber,
            tag : key.keyTag,
            location : key.location,
            code: key.keyCode
        })
    });
    return {result : resultArray};
}
