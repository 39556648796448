import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from 'layouts/dashboardLayout'
import { getAllKeyLoanData, getAllKeysData, getAllOutStandingKeys } from 'services/keyServices';
import DataTable from 'react-data-table-component';
import Key from 'images/key.png';
import OutstandingKey from 'images/outstanding.png';
import UserContext                              from '../contexts/userContext'
import { Bar, Pie, Line } from 'react-chartjs-2';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS } from 'chart.js/auto';


function Dashboard() {

    const [selectedUnit, setSelectedUnit] = useState('week');
    const [chartData, setChartData] = useState({});
    const [pieData, setPieData] = useState({});
    const [data, setData] = useState([]);
    const [allKeyData, setAllKeyData] = useState([]);
    const [outstandingKeyData, setOutstandingKeyData] = useState([]);

    const {user} = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [selectedUnit]);

    const onUnitSelected = async (e) => {
        setSelectedUnit(e.target.value);
        const transformedData = transformData(data, e.target.value);
        setChartData(transformedData);
    }

    const fetchData = async () => {
        const response = await getAllKeyLoanData(user.buildingId);
        const allKeyResponse = await getAllKeysData(user.buildingId);
        const outstandingResponse = await getAllOutStandingKeys(user.buildingId);
        setOutstandingKeyData(outstandingResponse.result);
        setAllKeyData(allKeyResponse.result);
        const transformedData = transformData(outstandingResponse.result ,response.result, selectedUnit);
        const topKeysData = getTopIssuedKeys(response.result, selectedUnit);
        setChartData(transformedData);
        setPieData(topKeysData);
    }

    const transformData = (outstandingKeyData, data, unit) => {
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const startOfWeek = moment().startOf('isoWeek');
        const endOfWeek = moment().endOf('isoWeek');
        const daysDataIssued = Array(7).fill(0);
        const daysDataReturned = Array(7).fill(0);

        const monthsDataIssued = Array(moment().month() + 1).fill(0); // Array to track monthly issued data
        const monthsDataReturned = Array(moment().month() + 1).fill(0); // Array to track monthly returned data

        outstandingKeyData.forEach(item => {
            const issueTime = moment(item.issueTime, 'MM/DD/YYYY hh:mm a');
            // const returnTime = moment(item.returnTime, 'MM/DD/YYYY hh:mm a');

            if (issueTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
            const dayIndex = daysOfWeek.indexOf(issueTime.format('dddd'));
            daysDataIssued[dayIndex]++;
            }

            // if (returnTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
            //     const dayIndex = daysOfWeek.indexOf(returnTime.format('dddd'));
            //     daysDataReturned[dayIndex]++;
            //     }

            // Increment monthly count for issued and returned keys
            const monthIndex = issueTime.month();
            monthsDataIssued[monthIndex]++;
            // if (returnTime.isValid()) {
            //     monthsDataReturned[monthIndex]++;
            // }
        });
        
        data.forEach(item => {
            const issueTime = moment(item.issueTime, 'MM/DD/YYYY hh:mm a');
            const returnTime = moment(item.returnTime, 'MM/DD/YYYY hh:mm a');

            if (issueTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
            const dayIndex = daysOfWeek.indexOf(issueTime.format('dddd'));
            daysDataIssued[dayIndex]++;
            }

            if (returnTime.isBetween(startOfWeek, endOfWeek, 'day', '[]')) {
                const dayIndex = daysOfWeek.indexOf(returnTime.format('dddd'));
                daysDataReturned[dayIndex]++;
                }

            // Increment monthly count for issued and returned keys
            const monthIndex = returnTime.month();
            monthsDataIssued[monthIndex]++;
            if(returnTime.isValid()) {
                monthsDataReturned[monthIndex]++;
            }
        });

        
        if (unit === 'this-month') {
            const startOfMonth = moment().startOf('month');
            const weeksDataIssued = Array(startOfMonth.clone().endOf('month').isoWeek() - startOfMonth.isoWeek() + 1).fill(0);
            const weeksDataReturned = Array(startOfMonth.clone().endOf('month').isoWeek() - startOfMonth.isoWeek() + 1).fill(0);
            
            outstandingKeyData.forEach(item => {
                const issueTime = moment(item.issueTime, 'MM/DD/YYYY hh:mm a');
                // const returnTime = moment(item.returnTime, 'MM/DD/YYYY hh:mm a');
          
                if (issueTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                  const weekNumber = issueTime.isoWeek() - startOfMonth.isoWeek();
                  weeksDataIssued[weekNumber]++;
                }
          
                // if (returnTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                //   const weekNumber = returnTime.isoWeek() - startOfMonth.isoWeek();
                //   weeksDataReturned[weekNumber]++;
                // }
            });

            // Populate week data
            data.forEach(item => {
              const issueTime = moment(item.issueTime, 'MM/DD/YYYY hh:mm a');
              const returnTime = moment(item.returnTime, 'MM/DD/YYYY hh:mm a');
        
              if (issueTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                const weekNumber = issueTime.isoWeek() - startOfMonth.isoWeek();
                weeksDataIssued[weekNumber]++;
              }
        
              if (returnTime.isBetween(startOfMonth, startOfMonth.clone().endOf('month'), 'day', '[]')) {
                const weekNumber = returnTime.isoWeek() - startOfMonth.isoWeek();
                weeksDataReturned[weekNumber]++;
              }
            });
        
            return {
              labels: Array(weeksDataIssued.length).fill(0).map((_, index) => `Week ${index + 1}`),
              datasets: [
                {
                  label: 'Issued',
                  data: weeksDataIssued,
                  backgroundColor: '#00B9AE',
                },
                {
                  label: 'Returned',
                  data: weeksDataReturned,
                  backgroundColor: '#037171',
                },
              ],
            };
          }
        
          return {
            labels: unit === 'week' ? daysOfWeek : moment.monthsShort(), // Use short month names
            datasets: [
              {
                label: 'Issued',
                data: unit === 'week' ? daysDataIssued : monthsDataIssued,
                backgroundColor: '#00B9AE',
              },
              {
                label: 'Returned',
                data: unit === 'week' ? daysDataReturned : monthsDataReturned,
                backgroundColor: '#037171',
              },
            ],
          };
      };

      const getTopIssuedKeys = (data, unit) => {
        let startDate;
        if (unit === 'week') {
          startDate = moment().startOf('isoWeek');
        } else if (unit === 'this-month') {
          startDate = moment().startOf('month');
        } else if (unit === 'month') {
          startDate = moment().startOf('year');
        }
      
        // const filteredData = data.filter(item => {
        //   const issueTime = moment(item.issueTime, 'MM/DD/YYYY hh:mm a');
        //   return issueTime.isBetween(startDate, moment(), 'day', '[]');
        // });
      
        const keyCounts = {};
        
        data.forEach(item => {
          const key = item.code;
      
          if (!keyCounts[key]) {
            keyCounts[key] = { count: 1, tag: item.tag }; // Store both count and tag
          } else {
            keyCounts[key].count++;
          }
        });
      
        const sortedKeys = Object.keys(keyCounts).sort((a, b) => keyCounts[b].count - keyCounts[a].count);
        const topKeys = sortedKeys.slice(0, 5);
      
        const pieData = topKeys.map(key => keyCounts[key].count);
        const pieLabels = topKeys.map(key => keyCounts[key].tag); // Use the 'tag' as labels
      
        return {
          topKeys,
          pieData,
          pieLabels
        };
      };


      const columns = [
        {
            name: 'Key Tag',
            selector: row => row.tag,
        },
        {
            name: 'Issued On',
            selector: row => row.issueTime,
        },
    ];
    
    const tableData = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

  return (
    <DashboardLayout pageTitle="Dashboard">
        <div className='dashboard-screen'>
            <div className='stat-container'>
                <div className='stat total-keys' onClick={() => navigate('/key-registry')}>
                    <div className='icon'>
                        <img src={Key} />
                    </div>
                    <div className='content'>
                        <p>Total Keys</p>
                        <h3>{allKeyData.length > 0 ? allKeyData.length : '00'}</h3>
                    </div>
                </div>
                <div className='border-line'></div>
                <div className='stat outstanding-keys' onClick={() => navigate('/outstanding-keys')}>
                    <div className='icon'>
                        <img src={OutstandingKey} />
                    </div>
                    <div className='content'>
                        <p>Outstanding Keys</p>
                        <h3>{outstandingKeyData.length < 10 ? `0${outstandingKeyData.length}` : outstandingKeyData.length}</h3>
                    </div>
                </div>
            </div>
            <div className='dashboard-container'>
                <div className='summary-container'>
                    <h3 className='heading'>Overall Summary</h3>
                    <div className='options-container'>
                        <div className={`option ${selectedUnit == 'week' ? 'selected' : ''}`} onClick={() => setSelectedUnit('week')}>This Week</div>
                        <div className={`option ${selectedUnit == 'this-month' ? 'selected' : ''}`} onClick={() => setSelectedUnit('this-month')}>This Month</div>
                        <div className={`option ${selectedUnit == 'month' ? 'selected' : ''}`} onClick={() => setSelectedUnit('month')}>This Year</div>
                    </div>
                    {/* <select value={selectedUnit} onChange={onUnitSelected}>
                        <option value="week">This Week</option>
                        <option value="this-month">This Month</option>
                        <option value="month">This Year</option>
                    </select> */}
                    <div className='charts-container'>
                        <div className='chart-item'>
                        {chartData.labels && chartData.datasets && (
                            <Bar 
                                data={chartData} 
                                options={{ 
                                    stacked: true, 
                                    responsive: true, 
                                    maintainAspectRatio : false,
                                    scales : {
                                        x: {
                                            ticks: {
                                                maxRotation: 90, // Rotate x-axis labels for better visibility
                                                autoSkip: false, // Prevent labels from being skipped
                                                fontSize: 12, // Adjust font size for labels
                                            },
                                        },
                                        y: {
                                        },
                                    },
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Key Issue & Return Count',
                                            font: {
                                                size: 16,
                                                weight: 'bold',
                                            },
                                        },
                                    },
                                }} 
                            />
                        )}
                        </div>
                        <div className='chart-item'>
                        {pieData.pieLabels && pieData.pieData && (
                        <Bar data={{
                            labels: pieData.pieLabels,
                            datasets: [{
                            data: pieData.pieData,
                            backgroundColor: ['#00B9AE','#037171','#03312E','#02C3BD','#009F93']
                            }]
                        }} options={{
                            plugins: {
                            legend: {
                                display: false // Set the position of the legend to 'right'
                            },
                            title: {
                                display: true,
                                text: 'Most Issued Keys',
                                font: {
                                    size: 16,
                                    weight: 'bold',
                                },
                            },
                            },
                            maintainAspectRatio : false,
                            responsive: true,
                        }} />
                        )}
                        </div>
                    </div>
                </div>
                <div className='outstanding-keys-container'>
                    <h3 className='heading'>Recently Issued Keys</h3>
                    <div className='key-table'>
                        <DataTable
                            columns={columns}
                            data={outstandingKeyData}
                            highlightOnHover
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                            paginationComponentOptions={{
                                rowsPerPageText: "Records per page:",
                                rangeSeparatorText: "out of"
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </DashboardLayout>
  )
}

export default Dashboard