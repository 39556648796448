import React, {useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkIfEmpty, isValidEmail } from '../functions/functions';
import { signIn } from '../services/authServices';
import { getBuildingNameById } from '../services/buildingServices';
import LabelInput from '../components/inputs/labelInput'
import SelectInput from 'components/inputs/selectInput';
import SelectBox from '../components/inputs/selectBox';
import ThemeButton from '../components/buttons/themeButton'
import UserContext from '../contexts/userContext';
import BasicThemeButton from 'components/buttons/basicThemeButton';
import UserService from "services/userServices";
import logo from '../images/The Key Power Logo.png';
import '../sass/main.scss'
import { showErrorToast } from 'utils/toasts';

function LoginScreen() {

    const { signOutUser} = UserService();

    const {user, saveUserDetails} = useContext(UserContext);
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
    const [isBuildingFormVisible, setIsBuildingFormVisible] = useState(false);
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState([]);
    const [userData, setUserData] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onLoginClick = async (e) => {
        e.preventDefault();

        setLoading(true);

        const errorObj = {
            emailAddressError : false,
            passwordError : false
        }

        const errorMessageObj = {
            emailErrorMessage : '',
            passwordErrorMessage : ''
        }

        if(checkIfEmpty(emailAddress)) {
            errorObj.emailAddressError = true;
            errorMessageObj.emailErrorMessage = 'Email is Required';
        } else if(!isValidEmail(emailAddress)) {
            errorObj.emailAddressError = true;
            errorMessageObj.emailErrorMessage = 'Please enter a valid email';
        }

        if(checkIfEmpty(password)) {
            errorObj.passwordError = true;
            errorMessageObj.passwordErrorMessage = 'Password is Required';
        }

        if(!errorObj.emailAddressError && !errorObj.passwordError) {
            const res = await signIn(emailAddress, password);
            if(res.status) {
                const data = res.data;
                if(data.approvalFlag == true && data.accountType !== 'guest' && data.accountType !== 'contractor-guest') {
                    if(data.accountType !== 'contractor') {
                        const buildingResponse = await getBuildingNames(data.approvedBuildings);
                        setBuildingOptions(buildingResponse);
                        setIsLoginFormVisible(false);
                        setIsBuildingFormVisible(true);
                    }
                    const userData = {
                        userName : data.fullName,
                        email : data.email,
                        userId : data.userId,
                        userType : data.accountType,
                        approvedBuildings : data.approvedBuildings,
                        manager : data.manager
                    }
                    setUserData(userData);
                    if(data.accountType == 'contractor') {
                        await saveUserDetails(userData);
                        navigate('/contractor-dashboard')
                    }
                } else {
                    toast.error("Not Authorized");
                    signOutUser();
                }
            } else {
                toast.error("Invalid Credentials");
                if(res.errorCode == 'auth/user-not-found') {
                    errorObj.emailAddressError = true;
                    errorMessageObj.emailErrorMessage = 'Email Not Found';
                } else {
                    errorObj.passwordError = true;
                    errorMessageObj.passwordErrorMessage = 'Email & password does not match';
                    errorObj.emailAddressError = true;
                    errorMessageObj.emailErrorMessage = 'Email & password does not match';
                }
                
            } 
            
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);

        setLoading(false)
    }


    const onContinueClick = (e) => {
        e.preventDefault();
        setLoading(true);
        if(selectedBuilding == '' || selectedBuilding == undefined || selectedBuilding == null) {
            toast.error("Select a building");
        } else {
            getBuildingNameById(selectedBuilding).then(async (res) => {
                userData.buildingId = selectedBuilding;
                userData.buildingName = res.name;
                await saveUserDetails(userData);
                if(userData.userType === 'contractor') navigate('/contractor-dashboard');
                else navigate('/dashboard');  
            })  
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    const getBuildingNames = async (buildingArray) => {
        let buildingNameArray = [{label : 'Select Building', value : ''}]
        for(let i = 0; i < buildingArray.length; i++) {
            await getBuildingNameById(buildingArray[i]).then((res) => {
                buildingNameArray.push({
                    label : res.name,
                    value : buildingArray[i]
                });
            })
        }
        return buildingNameArray;
    }


    const onEmailChange = (e) => {
        setEmailAddress(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const onBuildingSelected = (e) => {
        setSelectedBuilding(e.value);
    }

    const logoutUser = async () => {
        const response = await signOutUser();
        if(response) {
            setEmailAddress('');
            setPassword('');
            setIsBuildingFormVisible(false);
            setIsLoginFormVisible(true);
        } else {
            showErrorToast('Something Went Wrong.')
        }
    }

  return (
    <div className="login-screen">
        <div className="row">
            <div className="logo-container">
                <img src={logo} alt="" />
            </div>
            <div className="input-container">
                <h1>Login to Dashboard</h1>
                {isLoginFormVisible ?
                <form className='login-form'>
                    <LabelInput 
                        isError = {error.emailAddressError} 
                        errorMessage={errorMessages.emailErrorMessage} 
                        value={emailAddress} 
                        label="Email Address" 
                        onChange={onEmailChange}
                        mandatory={true} 
                        placeholder="Enter Email Address" 
                    />
                    <LabelInput 
                        isError = {error.passwordError} 
                        errorMessage={errorMessages.passwordErrorMessage} 
                        value={password} 
                        label="Password" 
                        onChange={onPasswordChange} 
                        placeholder="Enter Password"
                        mandatory={true} 
                        type="password" 
                    />
                    <div className="btn-container">
                        {/* <ThemeButton status={true} title="Login" onPress={onLoginClick} /> */}
                        <BasicThemeButton onClick={onLoginClick} title="Login" disableStatus={loading} />
                    </div>
                    <p className='reset-password'>Forgot Password ? <span className='link'><a onClick={() => navigate('/reset-password')}>Reset Now</a></span></p>
                    <p className='reset-password'>New Reception User ? <span className='link'><a onClick={() => navigate('/register')}>Create New Account</a></span></p>
                </form> : '' }
                {isBuildingFormVisible ?
                <form className='building-select-form'>
                    <SelectInput label={true} labelValue="Building Name" placeholder="Select Building Name" selectedOption={selectedBuilding} onChange={onBuildingSelected} options={buildingOptions} />
                    <div className="btn-container">
                        <BasicThemeButton onClick={onContinueClick} title="Continue" disableStatus={loading} />
                    </div>
                    <p className='reset-password'>Login to Another Account ? <span className='link'><a onClick={logoutUser}>Sign Out</a></span></p>
                </form> : ''}
            </div>
        </div>
        <p className='credits'>TheKeyPower from ViPower</p>
        
    </div>
  )
}

export default LoginScreen