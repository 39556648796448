import React, {useContext, useEffect} from 'react';
import SidebarContext from 'contexts/sidebarContext';

function RightSidebar({children}) {

  const {rightSidebarStatus, setRightSidebarStatus, rightSidebarRef} = useContext(SidebarContext);

  return (
    <div className={`right-sidebar ${rightSidebarStatus ? "active" : ""}`} ref={rightSidebarRef}>
        {children}
    </div>
  )
}

export default RightSidebar