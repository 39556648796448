import React, { useContext } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import UserContext from './contexts/userContext';

/** Function to only allow manager and reception user to access main portal
**/
function PortalRoute({children}) {
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    return (
        user.userType == 'user' || user.userType == 'manager' ? children : <Navigate to='/contractor-dashboard' replace />
    )
    
}

export default PortalRoute;