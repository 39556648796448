import { useState, useEffect, useContext, useCallback }      from "react";
import { useParams }                            from "react-router-dom";
import UserService                              from 'services/userServices';
import UserContext                              from "contexts/userContext";
import SidebarContext                           from 'contexts/sidebarContext';
import { getBuildingNameById }                  from "services/buildingServices";
import { showErrorToast, showSuccessToast }     from "utils/toasts";

const useUserDetails = () => {

    const {id} = useParams();

    const {user} = useContext(UserContext);

    const {rightSidebarStatus, setRightSidebarStatus} = useContext(SidebarContext);

    const { getUserData, revokeBuildingAccess, addBuildingAccess, getUserEventLogs, changeUserStatus } = UserService();

    const [data, setData]                                               = useState([]);
    const [approvedBuildings, setApprovedBuildings]                     = useState([]);
    const [searchApprovedBuilding, setSearchApprovedBuilding]           = useState('');
    const [filteredApprovedBuildings, setFilteredApprovedBuildings]     = useState([]);
    const [eventLog, setEventLog]                                       = useState([]);
    const [filteredEventLog, setFilteredEventLog]                       = useState([]);
    const [switchStatus, setSwicthStatus]                               = useState(false);
    const [addBuildingModal, setAddBuildingModal]                       = useState(false);
    const [approvedBuildingCheckBoxes, setApprovedBuildingCheckBoxes]   = useState([]);
    const [userApprovedBuildings, setUserApprovedBuildings]             = useState([]);
    const [filterUserApprovedBuildings, setFilterUserApprovedBuildings] = useState([]);
    const [addBuildingCheckBoxes, setAddBuildingCheckBoxes]             = useState([]);
    const [isLoading, setIsLoading]                                     = useState(true);
    const [isBuildingListLoading, setIsBuildingListLoading]             = useState(true);
    const [fetchApprovedBuildingsCalled, setFetchApprovedBuildingsCalled] = useState(false);

    const buildings = [
        {
            'name' : 'Building 1',
            'value' : 'building-1'
        },
        {
            'name' : 'Building 2',
            'value' : 'building-2'
        },
        {
            'name' : 'Building 3',
            'value' : 'building-3'
        },
    ]

    useEffect(() => {
        fetchUserData();

        if(rightSidebarStatus && !fetchApprovedBuildingsCalled) {
            fetchApprovedBuildings();
            setFetchApprovedBuildingsCalled(true);
        }
    },[rightSidebarStatus]);

    useEffect(() => {
        if(window.sessionStorage.getItem("revokeBuildingStatus") == 'true') {
            showSuccessToast('Building Access Revoked Successfully');
            window.sessionStorage.removeItem("revokeBuildingStatus")
        }
    }, [])

    const fetchUserData = async () => {
        const response = await getUserData(id);
        setData(response);
        let manager = response.manager;
        setSwicthStatus(response.approvalFlag);
        if(response.accountType === 'contractor') {
            const approvedBuildingList = await Promise.all(response.approvedBuildings[manager].map(async (building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName,
                    'id' : building
                };
            }));
            let checkBoxList = {};
            approvedBuildingList.forEach((building) => {
                let buildingId = building.id;
                checkBoxList[buildingId] = false
            })
            setApprovedBuildingCheckBoxes(checkBoxList);
            setApprovedBuildings(approvedBuildingList);
            setFilteredApprovedBuildings(approvedBuildingList);
        } else if(response.accountType === 'contractor-guest') {
            let buildingIdList = [];
            for(const [contractor, buildings] of Object.entries(response.approvedBuildings[manager])) {
                buildingIdList.push(...buildings)
            }

            const approvedBuildingList = await Promise.all(buildingIdList.map(async (building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName,
                    'id' : building
                };
            }));
            let checkBoxList = {};
            approvedBuildingList.forEach((building) => {
                let buildingId = building.id;
                checkBoxList[buildingId] = false
            })
            setApprovedBuildingCheckBoxes(checkBoxList);
            setApprovedBuildings(approvedBuildingList);
            setFilteredApprovedBuildings(approvedBuildingList);
        } else {
            const approvedBuildingList = await Promise.all(response.approvedBuildings.map(async (building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName,
                    'id' : building
                };
            }));
            let checkBoxList = {};
            approvedBuildingList.forEach((building) => {
                let buildingId = building.id;
                checkBoxList[buildingId] = false
            })
            setApprovedBuildingCheckBoxes(checkBoxList);
            setApprovedBuildings(approvedBuildingList);
            setFilteredApprovedBuildings(approvedBuildingList);
        }

        


        const eventLogResponse = await getUserEventLogs(id);

        
        
        setEventLog(eventLogResponse);
        setFilteredEventLog(eventLogResponse);

        setTimeout(() => {
            setIsLoading(false);
        }, 500)
    }


    const fetchApprovedBuildings = async () => {
        setIsBuildingListLoading(true);
        const approvedBuildingList = await Promise.all(
  
            user.approvedBuildings.map(async(building) => {
                const buildingName = await getBuildingNameById(building);
                return {
                    'name' : buildingName,
                    'id' : building
                };
            })
        );

        const response = await getUserData(id);
        let userApprovedBuildingList;
        if(response.accountType == 'contractor') {
            userApprovedBuildingList = response.approvedBuildings[response.manager];
        } else if(response.accountType == 'contractor-guest') {
            let buildingIdList = [];
            for(const [contractor, buildings] of Object.entries(response.approvedBuildings[response.manager])) {

                buildingIdList.push(...buildings)
            }
            userApprovedBuildingList = buildingIdList;
        } else {
            userApprovedBuildingList = response.approvedBuildings;
        }
        // const userApprovedBuildingList = response.accountType == 'contractor' ? response.approvedBuildings[response.manager] : response.approvedBuildings;

        const filteredBuildings = approvedBuildingList.filter(building =>
                !userApprovedBuildingList.some(approvedBuilding => approvedBuilding === building.id)
            );

        let checkBoxList = {};
        filteredBuildings.forEach((building) => {
            let buildingId = building.id;
            checkBoxList[buildingId] = false
        })

        setAddBuildingCheckBoxes(checkBoxList);
        setUserApprovedBuildings(filteredBuildings);
        setFilterUserApprovedBuildings(filteredBuildings);


        setTimeout(()=> {
            setIsBuildingListLoading(false);
        }, 500);
    };

    const handleAddBuildingCheckbox = (checkboxName) => {
        setAddBuildingCheckBoxes({
          ...addBuildingCheckBoxes,
          [checkboxName]: !addBuildingCheckBoxes[checkboxName]
        });
    };

    const addAccess = async () => {
        setIsLoading(true);
        const accessBuildings = Object.entries(addBuildingCheckBoxes)
                                    .filter(([key, value]) => value === true)
                                    .map(([key, value]) => key);

        if(accessBuildings.length > 0) {
            const response = await addBuildingAccess(id, data, accessBuildings);

            if(response) {
                setFetchApprovedBuildingsCalled(false);
                await fetchUserData();
                setRightSidebarStatus(false);

                setTimeout(() => {
                    setIsLoading(false);
                }, 500);

                showSuccessToast('Building Added Successfully');
            }
        } else {
            showErrorToast('Please Select a Building')
        }
        
    }

    const filterApprovedBuildings = (value) => {
        const filteredList = approvedBuildings.filter((building) => {
            return building.name.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredApprovedBuildings(filteredList);
    }

    const handleSwitchChange = async (switchStatus) => {
        
        const response = await changeUserStatus(id, data, switchStatus);

        if(response.status) {
            await fetchUserData();
            setSwicthStatus(switchStatus);
            showSuccessToast(response.message)
        } else {
            showErrorToast('Something Went Wrong');
        }
    }

    const addBuildingAccessModal = () => {
        setRightSidebarStatus(true);
    }

    const closeBuildingAccessModal = () => {
        setRightSidebarStatus(false);
    }

    const revokeAccess = async () => {
        setIsLoading(true);
        const revokedBuildings = Object.entries(approvedBuildingCheckBoxes)
                                    .filter(([key, value]) => value === true)
                                    .map(([key, value]) => key);
        const response = await revokeBuildingAccess(id, data, revokedBuildings);
        if(response) {
            window.sessionStorage.setItem("revokeBuildingStatus", true);
            window.location.reload(false);
        }  
    }


    const searchApprovedBuildings = (value) => {
        setSearchApprovedBuilding(value);
        const filteredList = userApprovedBuildings.filter((building) => {
            return building.name.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilterUserApprovedBuildings(filteredList);
    }


    return {
        data,
        setData,
        user,
        fetchUserData,
        approvedBuildings, 
        setApprovedBuildings,
        fetchApprovedBuildings,
        filteredApprovedBuildings, 
        setFilteredApprovedBuildings,
        searchApprovedBuilding, 
        setSearchApprovedBuilding,
        filterApprovedBuildings,
        addBuildingAccessModal,
        closeBuildingAccessModal,
        userApprovedBuildings, 
        setUserApprovedBuildings,
        searchApprovedBuildings,
        filterUserApprovedBuildings, 
        setFilterUserApprovedBuildings,
        eventLog,
        filteredEventLog,
        addBuildingCheckBoxes, 
        setAddBuildingCheckBoxes,
        handleAddBuildingCheckbox,
        addAccess,
        switchStatus,
        setSwicthStatus,
        handleSwitchChange,
        revokeAccess,
        approvedBuildingCheckBoxes, 
        setApprovedBuildingCheckBoxes,
        buildings,
        addBuildingModal, 
        setAddBuildingModal,
        isLoading, 
        setIsLoading,
        isBuildingListLoading, 
        setIsBuildingListLoading
    }

}

export default useUserDetails;