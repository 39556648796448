import React from 'react'

// CheckBox Input Component Without Label
// placeholder:   Placeholder value
// onChange:      When input value Changes

function CheckBoxInput({label, checked, onChange, id, disabled=false}) {
  return (
    <>
        <div className='checkbox-input'>
            <input id={id} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </div>
    </>
  )
}

export default CheckBoxInput

// Stylesheet: sass/components/inputs/_input.scss