import React from 'react'
import { BiMenuAltLeft, BiArrowBack } from "react-icons/bi";
import { IoMdCloseCircle } from 'react-icons/io';

// Basic Input Component Without Label
// placeholder:   Placeholder value
// onChange:      When input value Changes

function BasicInput({placeholder, value, setValue, onChange, background, isError, errorMessage, showErrorMessage = false}) {

  return (
    <>
        <div className="basic-input">
            <input type="text" placeholder={placeholder} value={value} onChange={onChange} style={{ backgroundColor : background ? background : '' }} />
            {value && <IoMdCloseCircle className='input-clear-text' size={15} onClick={() => setValue('')} /> }
            {showErrorMessage ?
            <p className={`error-message ${isError ? 'active' : ''}`}>{!errorMessage ? 'Error Message' : errorMessage}</p>
            : ''}
        </div>
    </>
  )
}

export default BasicInput

// Stylesheet: sass/components/inputs/_input.scss