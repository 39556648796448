import { useContext, useState, useEffect } from 'react';
import UserContext from 'contexts/userContext';
import BasicThemeButton from 'components/buttons/basicThemeButton';
import BasicInput from 'components/inputs/basicInput';
import LabelInput from 'components/inputs/labelInput';
import { showSuccessToast, showErrorToast } from 'utils/toasts';
import Modal from 'react-modal';
import CheckBoxInput from 'components/inputs/checkBoxInput';
import UserService from 'services/userServices';
import { checkIfEmpty, isValidEmail } from 'functions/functions';

const UserModal = ({modalOpen, closeModal, contractorApprovedBuildings, modalMode, selectedUser, fetchUserData}) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checkedBuildings, setCheckedBuildings] = useState([]);
    const [contractorApprovedList, setContractorApprovedList] = useState([]);
    const [othersApprovedList, setOthersApprovedList] = useState([]);
    const [error, setError] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [loader, setLoader] = useState({
        loading : false,
        submitting : false
    })

    const {user} = useContext(UserContext);

    const {registerNewUser, checkIfUserExists, updateContractorUser} = UserService();

    useEffect(() => {
        if(modalMode == "edit") {
            setUserData();
        }
    }, [selectedUser]);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          width : '35%',
          transform: 'translate(-50%, -50%)',
          minHeight : '30%'
        },
    };

    const setUserData = () => {
       setLoader({
        ...loader,
        loading : true
       });

       setName(selectedUser.fullName);
       setEmail(selectedUser.email);
       let checkedBuildingsList = [];
       let contractorApprovedList = [];
       let othersApprovedList = [];

       for(const [key, value] of Object.entries(selectedUser.approvedBuildings[user.manager])) {
            checkedBuildingsList.push(...value);
            if(key === user.userId) {
                contractorApprovedList.push(...value);
            } else {
                othersApprovedList.push(...value);
            }
       }

       setCheckedBuildings(checkedBuildingsList);
       setContractorApprovedList(contractorApprovedList);
       setOthersApprovedList(othersApprovedList);

       setLoader({
        ...loader,
        loading : false
       });
    }

    // Function to add new user
    const addUser = async () => {
        setLoader({
            ...loader,
            submitting : true
        })
        let errorObj = {
            name : false,
            email : false,
            password : false,
            building : false
        };

        let errorMessageObj = {
            name : '',
            email : '',
            password : '',
            building : ''
        }

        if(checkIfEmpty(name)) {
            errorObj.name = true;
            errorMessageObj.name = "Please enter name";
        }

        if(checkIfEmpty(email)) {
            errorObj.email = true;
            errorMessageObj.email = "Please enter email";
        } else if(!isValidEmail(email)) {
            errorObj.email = true;
            errorMessageObj.email = 'Please Enter a valid Email';
        } 
        else if(await checkIfUserExists(email)) {
            errorObj.email = true;
            errorMessageObj.email = 'User Exists Already';
        }

        if(checkIfEmpty(password)) {
            errorObj.password = true;
            errorMessageObj.password = "Please enter password"
        }

        if(checkedBuildings.length < 1) {
            errorObj.building = true;
            errorMessageObj.building = "Select atleast 1 building";
        }

        const userData = {
            accountType : 'contractor-guest',
            approvedBuildings : checkedBuildings,
            fullName : name,
            manager : user.manager,
            refKey : 'GUESTREF',
            contractor : user.userId
        }

        const hasError = Object.values(errorObj).some(value => value === true);


        if(!hasError) {

            try {
                
                    const response = await registerNewUser(email, password, userData);



                    if(response) {
                        const data = {
                            'name' : name,
                            'email_address' : email,
                            'subject' : 'TheKeyPower Invitation',
                            'password' : password
                        }
                        const emailRequest = await fetch(`https://api.thekeypower.co.uk/mail/guest-invitation`, {
                            method : 'POST',
                            headers : {
                                "Content-Type" : "application/json",
                                "Authorization" : "ha5Gta5a7na098jlvYHnjashdiIUhgaiudbgIOIUH876576asdbsdcsdchiu4a65s4d7stgd7c6s",
                            },
                            body : JSON.stringify(data)
                        });
        
                        const emailResponse = await emailRequest.json();

                        if(emailResponse.statusCode === 200) {
                            showSuccessToast('Guest User Registered Successfully');
                            fetchUserData();
                            closeUserModal();
                        }
                    } else {
                        showErrorToast('Something Went Wrong')
                    }
                

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }

        }

        setError(errorObj);
        setErrorMessage(errorMessageObj);
        setLoader({
            ...loader,
            submitting : false
        })

    }


    // Function to update user details
    const saveChanges = async () => {

        await updateContractorUser(selectedUser.userId, {
            fullName : name,
            [`approvedBuildings.${user.manager}.${user.userId}`] : checkedBuildings
            //approvedBuildings : checkedBuildings
        });
        fetchUserData();
        showSuccessToast('Updated Successfully');
        closeUserModal();
    }

    const changeUserStatus = async () => {
        await updateContractorUser(selectedUser.userId, {
            approvalFlag : !selectedUser.approvalFlag,
        });
        fetchUserData();
        showSuccessToast('Updated Successfully');
        closeUserModal();
    }

    const closeUserModal = () => {
        setName('');
        setEmail('');
        setPassword('');
        setCheckedBuildings([]);
        setError([]);
        setErrorMessage([]);
        closeModal();
    }

    const onCheckboxClicked = (building) => {

        setCheckedBuildings((prevCheckedBuildings) => {
            if(prevCheckedBuildings.includes(building)) {
                return prevCheckedBuildings.filter(buildingId => buildingId !== building);
            } else {
                return [...prevCheckedBuildings, building]
            }
        });
    }


    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeUserModal}
            style={customStyles}
            contentLabel="Example Modal">
                {!loader.loading ?
                <div className='add-user-contractor-modal'>
                    <h2>{`${modalMode === 'add' ? 'Add New' : 'Edit'} User`}</h2>
                    <div className='mt-2'>
                        <LabelInput isError={error.name} errorMessage={errorMessage.name} label="Full Name" placeholder="Enter Full Name" onChange={(e) => setName(e.target.value)} value={name} />
                        <div className="row gap-1">
                            <LabelInput isError={error.email} errorMessage={errorMessage.email} label="Email Id" disabled={modalMode === 'edit'} placeholder="Enter Email Id" onChange={(e) => setEmail(e.target.value)} value={email} />
                            {modalMode == 'add' ? <LabelInput isError={error.password} errorMessage={errorMessage.password} label="Password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} value={password} /> : '' }
                        </div>
                        <div className='buildings-options'>
                            <p className='label'>Select Buildings</p>
                            {error.building ? <p style={{ color : '#a30000', fontSize : '1rem' }}>{errorMessage.building}</p> : '' }
                            <div className='buildings-options__options'>
                                {contractorApprovedBuildings.map((building) => {
                                    return (
                                        <CheckBoxInput key={building.id} disabled={othersApprovedList.includes(building.id)} checked={checkedBuildings.includes(building.id)} onChange={() => onCheckboxClicked(building.id)} label={building.name} id={building.id} />
                                    )
                                })}
                                
                            </div>
                        </div>
                        <div className='row gap-1 justify-end mt-2'>
                            {modalMode === 'edit' ? <BasicThemeButton disableStatus={loader.submitting} title={selectedUser.approvalFlag ? 'Disable User' : 'Enable User'} color={selectedUser.approvalFlag ? `#ffe6e6` : `#e5e4f5`} fontColor={selectedUser.approvalFlag ? `#ad2e2a` : `#292561`} onClick={changeUserStatus} /> : '' }
                            <BasicThemeButton disableStatus={loader.submitting} title={modalMode === 'add' ? 'Send Invitation' : 'Save Changes'} onClick={modalMode === 'add' ? addUser : saveChanges} />
                        </div>
                    </div>
                </div> : '' }
        </Modal>
    )
}

export default UserModal