
import { db } from '../firebaseConfig';
import {  doc, getDoc } from "firebase/firestore";

// Function to check the input value is empty or not
export const checkIfEmpty = (input) => {
    if(input == '' || input == undefined || input == null) {
        return true
    }
    return false
}

// Function to check if email is valid or not
export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}


// Function to generate 6 character alpha numeric code
export const generateReferenceKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};


// Check if Reference Key Already Exists
export const checkReferenceKeyExists = async (referenceKey, id) => {
    const refKeysDocRef = doc(db, 'account', id);
    const docSnapshot = await getDoc(refKeysDocRef);
    const data = docSnapshot.data();

    if (data && data.pendingRefKeys && data.pendingRefKeys.includes(referenceKey)) {
        return true;
      }
      
    return false;
};


// Function to capitalize each word in a string
export const capitalizeWords = (inputString) => {
    // Split the input string into an array of words
    const wordsArray = inputString.split(' ');
  
    // Capitalize each word in the array using the map function
    const capitalizedWordsArray = wordsArray.map((word) => {
      // Capitalize the first letter of each word and keep the rest unchanged
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    // Join the capitalized words back into a single string
    const capitalizedString = capitalizedWordsArray.join(' ');
  
    return capitalizedString;
}