import  { createContext, useState, useEffect, useRef } from 'react';

const HeaderContext = createContext();

export function HeaderProvider({children}) {
    const [buildingOptionStatus, setBuildingOptionStatus] = useState(false);
    const [profileOptionStatus, setProfileOptionStatus] = useState(false);
    const buildingOptionRef = useRef(null);
    const profileOptionRef = useRef(null);

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);
    
        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    },[])
    
    function handleClickOutside(event) {
        if (buildingOptionRef.current && !buildingOptionRef.current.contains(event.target)) {
            setBuildingOptionStatus(false);
        }

        if (profileOptionRef.current && !profileOptionRef.current.contains(event.target)) {
            setProfileOptionStatus(false);
        }
    }

    return (
        <HeaderContext.Provider value={{buildingOptionStatus, setBuildingOptionStatus, profileOptionStatus, setProfileOptionStatus, buildingOptionRef, profileOptionRef }}>{children}</HeaderContext.Provider>
    )
      
}

export default HeaderContext;