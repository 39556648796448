import { useState } from "react";
import { checkIfEmpty, isValidEmail } from "functions/functions";
import { useNavigate } from "react-router-dom";
import UserService from "services/userServices";
import { showSuccessToast, showErrorToast } from "utils/toasts";

const useRegisterUser = (e) => {

    const {checkEmailReferenceValidation, getUserApprovedBuildings, createNewUser, addUserToFireStore, deleteUserAuth, signOutUser, removePendingData} = UserService();

    const navigate = useNavigate();

    const [role, setRole]                   = useState('')
    const [fullName, setFullName]           = useState('');
    const [emailAddress, setEmailAddress]   = useState('');
    const [referenceKey, setRefernceKey]    = useState('');
    const [password, setPassword]           = useState('');
    const [errorStatus, setErrorStatus]     = useState(false);
    const [errorMessage, setErrorMessage]   = useState('');
    const [loading, setLoading]             = useState(false);

    const onRegisterUserClick = async (e) => {

        e.preventDefault();

        setLoading(true);

        let errorStatusObj = {};
        let errorMessageObj = {};

        let name = fullName.trim();
        let email = emailAddress.trim();
        let refKey = referenceKey.trim();
        let pass = password.trim();

        if(checkIfEmpty(role)) {
            errorStatusObj.role = true;
            errorMessageObj.role = "Role is Mandatory";
        }

        if(checkIfEmpty(name)) {
            errorStatusObj.name = true;
            errorMessageObj.name = 'Name is Mandatory';
        };

        if(checkIfEmpty(email)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Email Address is Mandatory';
        } else if(!isValidEmail(email)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Please enter a valid email';
        }

        if(checkIfEmpty(refKey)) {
            errorStatusObj.refKey = true;
            errorMessageObj.refKey = 'Reference Key is Mandatory';
        };

        if(checkIfEmpty(pass)) {
            errorStatusObj.password = true;
            errorMessageObj.password = 'Password is Mandatory';
        };

        if(!errorStatusObj.role && !errorStatusObj.name && !errorStatusObj.email && !errorStatusObj.refKey && !errorStatusObj.password) {
        
            try {
                // Check the validity of email and reference key
                const emailReferenceValidationResponse = await checkEmailReferenceValidation(email, refKey, role);
                
                // If not valid
                if(!emailReferenceValidationResponse.status) {
                    throw emailReferenceValidationResponse.message;
                } else {
                    const responseData = emailReferenceValidationResponse.data;
                    let approvedBuildings;
                    // If the invited user have access to all buildings
                    if(responseData.assignedBuilding == '_all') {
                        const response = await getUserApprovedBuildings(responseData.managerId);
                        if(response) {
                            approvedBuildings = response.data;
                        } else {
                            throw response.message;
                        }

                    } else {
                        approvedBuildings = [responseData.assignedBuilding];
                    }

                    const userData = {
                        accountType         : role == 'contractor' ? 'contractor' : 'user',
                        approvedBuildings   : approvedBuildings,
                        email               : email,
                        fullName            : name,
                        manager             : responseData.managerId,
                        refKey              : refKey
                    }

                    const registerResponse = await createNewUser(email, pass);

                    if(!registerResponse.status) {
                        showErrorToast(registerResponse.message);
                    } else {
                        const removePendingDataResponse = await removePendingData(responseData.index, responseData.managerEmail);

                        if(removePendingDataResponse.status) {
                            const userId = registerResponse.uId;
                            const response = await addUserToFireStore(userId, userData);

                            if(response.status) {
                                showSuccessToast('Account Registered Successfully. Please Login Now');
                                navigate('/');
                            } else {
                                await deleteUserAuth();
                                showErrorToast('Something Went Wrong');
                            }
                        } else {
                            showErrorToast('Something Went Wrong');
                        }
                        
                    }

                    await signOutUser();

                }
            } catch(err) {
                if(err == 'email-reference-does-not-match') {
                    showErrorToast('Email & Reference key do not match. Try Again.');
                } else if(err == 'not-invited') {
                    showErrorToast('The provided email is not invited.');
                } else if(err == 'invalid-role') {
                    showErrorToast('No access to selected role');
                }
                else {
                    showErrorToast(`Something Went Wrong ${err}`);
                }
            }

        }

        setErrorStatus(errorStatusObj);
        setErrorMessage(errorMessageObj);

        setTimeout(() => {
            setLoading(false)
        }, 500);

        
    }

    return {
        role, setRole,    
        fullName,
        setFullName,
        emailAddress,
        setEmailAddress,
        referenceKey,
        setRefernceKey,
        password,
        setPassword,
        onRegisterUserClick,
        errorStatus,
        errorMessage,
        loading,
        setLoading
    }
}

export default useRegisterUser;