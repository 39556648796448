import { initializeApp } from 'firebase/app';

export const initializeTemporaryFirebaseApp  = () => {
    const tempConfig = {
        apiKey: "AIzaSyCBlljp2aplabqV6_NIcunlvsMkRcjPDVU",
        authDomain: "thekeypower-flutter.firebaseapp.com",
        projectId: "thekeypower-flutter",
        storageBucket: "thekeypower-flutter.appspot.com",
        messagingSenderId: "26527281449",
        appId: "1:26527281449:web:13ca62ee19eb2cfb784738"
    }

    return initializeApp(tempConfig, 'temporaryApp');
}