import React from 'react'
import ContentLoader from 'react-content-loader';

function AvatarWithTextSkeleton () {
    return (
    <ContentLoader
      height={80}
      width='100%'
      viewBox="0 0 100% 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="35" cy="35" r="35" />
      <rect x="85" y="14" rx="3" ry="3" width="180" height="13" />
      <rect x="85" y="30" rx="3" ry="3" width="10" height="10" />
      <rect x="85" y="30" rx="3" ry="3" width="74" height="10" />
      <circle cx="95%" cy="27" r="8" />
      <rect x="85" y="53" rx="0" ry="0" width="100%" height="1" />
      <rect x="219" y="146" rx="0" ry="0" width="5" height="0" />
    </ContentLoader>
    )
}

export default AvatarWithTextSkeleton;