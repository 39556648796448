import { useState } from "react";
import UserService from "services/userServices";
import { checkIfEmpty, isValidEmail } from "functions/functions";
import { showSuccessToast, showErrorToast } from "utils/toasts";

const useResetPassword = (e) => {

    const {handlePasswordReset} = UserService();

    const [email, setEmail]                 = useState('');
    const [errorStatus, setErrorStatus]     = useState(false);
    const [errorMessage, setErrorMessage]   = useState('');
    const [loading, setLoading]             = useState('');

    const onResetBtnClick = async (e) => {
        e.preventDefault();
        
        setLoading(true);

        const errorStatusObj = {};
        const errorMessageObj = {};

        const emailAddress = email.trim();

        if(checkIfEmpty(emailAddress)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Email is mandatory';
        } else if(!isValidEmail(emailAddress)) {
            errorStatusObj.email = true;
            errorMessageObj.email = 'Please enter a valid email';
        }

        if(!errorStatusObj.email) {
            const response = await handlePasswordReset(email);
            if(response.status) {
                showSuccessToast('Reset mail has been sent to the email. Please check the mailbox.');
                setEmail('');
            } else {
                showErrorToast(response.message);
            }
        }

        setErrorStatus(errorStatusObj);
        setErrorMessage(errorMessageObj);

        setTimeout(() => {
            setLoading(false)
        }, 500);
        
    }

    return {
        email,
        setEmail,
        errorStatus,
        errorMessage,
        onResetBtnClick,
        loading,
    }
}

export default useResetPassword;