import React, {useContext, useState}     from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Logo from 'images/Logo-White.png';
import { userSignOut } from 'services/authServices';
import MenuItem                from './menuItem';
import SidebarContext          from 'contexts/sidebarContext';
import BasicThemeButton from 'components/buttons/basicThemeButton';
import UserContext from 'contexts/userContext';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width : '30%',
      transform: 'translate(-50%, -50%)',
    },
  };

  Modal.setAppElement('#root');

// Sidebar Component
function SideBar() {
    const navigate = useNavigate();
    const {sidebarStatus, sidebarRef} = useContext(SidebarContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const {user} = useContext(UserContext);

    // List of Menu Items on Sidebar.
    let menuItems = [
        {name : "Dashboard", exact: true, to: "/dashboard", iconClassName : "bi bi-speedometer2"},
        {name : "Key Registry", exact: true, to: "/key-registry", iconClassName : "bi bi-key-fill"},
        {name : "Outstanding Keys", exact: true, to: "/outstanding-keys", iconClassName : "bi bi-clipboard-data"},
        {name : "Key Loan History", exact: true, to: "/key-loan-history", iconClassName : "bi bi-card-checklist"},
    ]

    if(user.userType == "manager") {
        menuItems.push({
            name : "Manage Users",
            exact: true,
            to : "/manage-users",
            iconClassName : "bi bi-people-fill"
        })
    }

    // For contractor user, just show below options
    if(user.userType === "contractor") {
        menuItems = [
            {name : "Dashboard", exact: true, to: "/contractor-dashboard", iconClassName : "bi bi-speedometer2"},
        ]
    }

    const onLogoutPress = () => {
        userSignOut().then((res) => {
            if(res == true) {
                localStorage.removeItem('user');
                navigate("/");
            }
        });
    }

  return (
    <>
    <div className={`sidebar ${sidebarStatus ? "mobile-active" : ""}`} ref={sidebarRef}>
        <div className="top-section">
            <div className="logo" onClick={() => navigate('/dashboard')}>
                <img src={Logo} alt="Logo" />
            </div>
            
        </div>
        <div className="main-menu">
            <ul>
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        name={menuItem.name}
                        to={menuItem.to}
                        exact={menuItem.exact}
                        subMenus={menuItem.subMenus || []}
                        iconClassName={menuItem.iconClassName}
                    />
                ))}
                <li onClick={() => setModalIsOpen(true)}>
                    <a className='menu-item'>
                        <div className="menu-icon">
                            <i className='bi bi-box-arrow-left'></i>
                        </div>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <div className="modal-content logout-modal">
            <h2>Logout</h2>
            <p>Are you sure you want to logout from dashboard ?</p>
            <div className="btn-container">
                <BasicThemeButton color="#ededed" fontColor="#1a1a1a" title="No, Cancel" onClick={() => setModalIsOpen(false)} />
                <BasicThemeButton title="Yes, Logout" onClick={onLogoutPress} />
            </div>
        </div>
    </Modal>
    </>
  )
}

export default SideBar

// Stylesheet: sass/components/sidebar/_sidebar.scss